import produce from "immer";

import { UPDATE_CONFIG, ConfigActionType, DeviceConfigState } from "./types";
import { RESET_KIOSK } from "../actionTypes";
import { ResetKioskAction } from "../auth/types";

const initialConfigState: DeviceConfigState = {
  organizationId: null,
  locationId: null,
  locationName: null,
  questionId: null,
  questionUuid: null,
  kioskId: null,
  kioskUuid: null,
  kioskName: null,
  heartbeatInterval: 60000,
  layerTimeoutMs: 8000,
  languageRotationIntervalMs: 6000,
};

const reducer = (
  state = initialConfigState,
  action: ConfigActionType | ResetKioskAction
) =>
  produce(state, (_) => {
    switch (action.type) {
      case RESET_KIOSK:
        return initialConfigState;
      case UPDATE_CONFIG:
        return {
          ...state,
          ...action.payload,
        };
    }
  });

export default reducer;
