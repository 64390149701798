import { UPDATE_CONFIG, ConfigActionType, DeviceConfigState } from "./types";

let updateDeviceConfig = (
  config: Partial<DeviceConfigState>
): ConfigActionType => ({
  type: UPDATE_CONFIG,
  payload: config,
});

export { updateDeviceConfig };
