export const UPDATE_CONFIG = "device/config/update";

export interface DeviceConfigState {
  organizationId: number | null;
  locationId: number | null;
  locationName: string | null;
  questionId: number | null;
  questionUuid: number | null;
  kioskId: number | null;
  kioskUuid: string | null;
  kioskName: string | null;
  heartbeatInterval: number;
  layerTimeoutMs: number;
  languageRotationIntervalMs: number;
}

interface UpdateDeviceConfigAction {
  type: typeof UPDATE_CONFIG;
  payload: Partial<DeviceConfigState>;
}

export type ConfigActionType = UpdateDeviceConfigAction;
