import React from "react";

import Button from "./AdminMenu/Button";
import PinCode from "./PinCode";
import QRCode from "qrcode.react";
import LogoFlag from "./LogoFlag";

type Props = {
  envURL: string;
  pinCode: string | null;
  onRefreshPIN: () => void;
};

const ActivationScreen: React.FC<Props> = ({
  envURL,
  pinCode,
  onRefreshPIN,
}) => (
  <div className="activation-screen">
    <div style={{ marginTop: "-0.75vh" }}>
      <LogoFlag text="Activate Kiosk" />
    </div>

    <div className="instructions">
      <div className="qr-panel">
        <p>Scan this QR code</p>
        <QRCode
          includeMargin={false}
          level="L"
          value={`${envURL}/kiosks/activate/${pinCode}`}
        />
      </div>

      <div className="or-divider">
        <span>or</span>
      </div>

      <div className="pin-panel">
        <p>
          On your phone or computer, go to
          <br />
          <strong>{`${envURL}/activate`}</strong>
          <br />
          and enter this code
        </p>

        <PinCode text={pinCode} />
        <div>
          <Button
            onClick={onRefreshPIN}
            text="Refresh Code"
            variant="primary"
            style={{
              lineHeight: 2,
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ActivationScreen;
