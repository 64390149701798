import produce from "immer";

import { RESET_KIOSK } from "../actionTypes";
import {
  HEARTBEAT_SUCCESS,
  HeartbeatActionType,
  HeartbeatState,
} from "./types";

let initialHeartbeatsState: HeartbeatState = {
  lastHeartbeat: null,
};

const reducer = (
  state = initialHeartbeatsState,
  action: HeartbeatActionType | { type: typeof RESET_KIOSK }
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_KIOSK:
        return initialHeartbeatsState;

      case HEARTBEAT_SUCCESS:
        draft.lastHeartbeat = action.payload.timestamp;
        break;
      default:
        break;
    }
  });

export default reducer;
