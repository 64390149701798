import produce, { Draft } from "immer";

import { ADD_LOG_ENTRY, CLEAR_LOG, LogActionTypes, LogState } from "./types";

const initialState: LogState = {
  entries: [],
};

const MAX_ENTRIES = 100;

const reducer = produce((draft: Draft<LogState>, action: LogActionTypes) => {
  switch (action.type) {
    case CLEAR_LOG:
      draft.entries = [action.payload];
      break;

    case ADD_LOG_ENTRY:
      if (draft.entries.length >= MAX_ENTRIES) {
        draft.entries.shift();
      }
      draft.entries.push(action.payload);
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
