import { DeviceConfigState } from "../device/types";

export const MESSAGES_RECEIVED = "messages/received";

export const UpdateDeviceConfig = "updateDeviceConfig";
export interface UpdateDeviceConfigMessage {
  service: typeof UpdateDeviceConfig;
  params: DeviceConfigState;
}

export const GetDeviceInfo = "getDeviceInfo";
export interface GetDeviceInfoMessage {
  service: typeof GetDeviceInfo;
  params?: {};
}

export const GetLogs = "getLogs";
export interface GetLogsMessage {
  service: typeof GetLogs;
  params?: {
    count?: number;
  };
}

export const InjectReduxAction = "injectReduxAction";
export interface InjectReduxActionMessage {
  service: typeof InjectReduxAction;
  type: string;
  payload: any;
}

type Message =
  | GetDeviceInfoMessage
  | GetLogsMessage
  | InjectReduxActionMessage
  | UpdateDeviceConfigMessage;

export interface MessageWrapper {
  kiosk: number | string;
  publishedAt: string;
  eventId: string;
  jobId: string;
  message: Message;
}

interface MessageReceivedAction {
  type: typeof MESSAGES_RECEIVED;
  payload: {
    messages: MessageWrapper[];
  };
}

export type MessageActionTypes = MessageReceivedAction;
