export const FETCH_PIN = "pin/fetching";
export const PIN_REFRESHING = "pin/refreshing";
export const PIN_SUCCESS = "pin/success";
export const PIN_ERROR = "pin/fail";

export const TOKEN_REFRESHING = "tokens/refreshing";
export const TOKENS_SUCCESS = "tokens/success";
export const TOKEN_ERROR = "tokens/fail";

export const QUESTION_REQUESTED = "question/fetching";
export const QUESTION_SUCCESS = "question/success";
export const QUESTION_ERROR = "question/fail";

export const HEARTBEAT_START = "heartbeats/start";
export const HEARTBEAT_STOP = "heartbeats/stop";
export const HEARTBEAT_SUCCESS = "heartbeats/success";
export const HEARTBEAT_FAIL = "heartbeats/fail";

export const SHOW_SPECIFIC_ANSWERS = "question/specific/show";

export const VOTE_BUTTON_TAPPED = "votes/tapped";
export const VOTE_SUBMIT = "votes/submit";
export const VOTE_SUCCESS = "votes/success";
export const VOTE_FAILURE = "votes/fail";
export const STORE_VOTES = "votes/store";
export const SEND_STORED_VOTES = "votes/stored/send";
export const CACHED_VOTES_SUBMIT_SUCCESS = "votes/stored/send/success";

export const OPEN_MENU = "admin/menu/open";
export const CLOSE_MENU = "admin/menu/close";
export const MENU_AUTH_ATTEMPT = "admin/auth/attempt";
export const MENU_AUTH_SUCCESS = "admin/auth/success";
export const MENU_AUTH_FAILURE = "admin/auth/fail";

export const UPDATE_CHECK = "update/check";
export const UPDATE_STATUS = "update/status";
export const UPDATE_AVAILABLE = "update/available";
export const UPDATE_NONE = "update/none";
export const UPDATE_SUCCESS = "update/success";
export const UPDATE_FAIL = "update/fail";

// Service Worker
export const SERVICE_WORKER_READY = "serviceWorker/ready";
export const UPDATE_SERVICE_WORKER_STATUS = "serviceWorker/status/update";

export const RESET_KIOSK = "kiosk/reset";
export const UPDATE_KIOSK_META = "kiosk/meta/update";
export const UPDATE_CONFIG = "@kiosk/config/update";

export const ADD_LOG_ENTRY = "logs/add";
export const CLEAR_LOG = "logs/clear";

export const QUEUE_MESSAGE = "messages/queue";
export const QUEUE_MESSAGES_BULK = "messages/queueMany";
export const MESSAGES_RECEIVED = "messages/received";

export const NEXT_LANGUAGE = "question/language/next";
export const START_LANGUAGE_ROTATION = "question/language/start";
export const PAUSE_LANGUAGE_ROTATION = "question/language/stop";
