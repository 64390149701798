export const HEARTBEAT_START = "heartbeats/start";
export const HEARTBEAT_STOP = "heartbeats/stop";
export const HEARTBEAT_SUCCESS = "heartbeats/success";
export const HEARTBEAT_FAIL = "heartbeats/fail";

export interface HeartbeatState {
  lastHeartbeat: string | Date | null;
}

interface StartHeartbeatAction {
  type: typeof HEARTBEAT_START;
}

interface HeartbeatSuccessAction {
  type: typeof HEARTBEAT_SUCCESS;
  payload: {
    timestamp: string;
  };
}

export type HeartbeatActionType = StartHeartbeatAction | HeartbeatSuccessAction;
