import { call, delay, put, race } from "redux-saga/effects";

import { callApi } from "../auth";
import { CancelToken } from "../client";
import { log } from "../logs/actions";
import { updateDeviceConfig } from "./actions";

function* getKioskConfig() {
  yield put(log("fetching device config"));
  let source = CancelToken.source();

  let { config, timeout } = yield race({
    config: call(callApi, {
      url: "/device/config/",
      method: "get",
      cancelToken: source.token,
    }),
    timeout: delay(10000),
  });

  if (timeout) {
    source.cancel();
    yield put(log("device config request exceeded 10s timeout"));
    return;
  }

  yield put(updateDeviceConfig(config));
}

export { getKioskConfig };
