import { Channel, channel, buffers } from "redux-saga";
import {
  takeLatest,
  delay,
  call,
  put,
  fork,
  take,
  race,
  select,
  flush,
} from "redux-saga/effects";

import { RESET_KIOSK } from "../actionTypes";

import { callApi } from "../auth";
import { CancelToken } from "../client";
import { HEARTBEAT_SUCCESS } from "../heartbeats/types";
import { log } from "../logs/actions";

import { nextLanguage } from "../actions/question";

import { PAUSE_LANGUAGE_ROTATION } from "../actionTypes";

import { getCurrentLocationAndQuestion } from "../device/selectors";

import {
  questionError,
  setQuestionData,
  voteFailure,
  voteSuccess,
  voteSubmit,
  showSecondaryAnswers,
  cachedVotesSubmitted,
  storeVotes,
  startLanguageRotation,
  pauseLanguageRotation,
} from "./actions";
import {
  answerHasSpecifics,
  getCachedVotes,
  getCurrentQuestion,
  getCurrentLanguage,
} from "./selectors";
import {
  VoteTappedAction,
  VotePayload,
  QUESTION_REQUESTED,
  VOTE_BUTTON_TAPPED,
  SEND_STORED_VOTES,
} from "./types";

export function* questionSaga() {
  yield takeLatest([QUESTION_REQUESTED], fetchQuestionData);
  yield takeLatest([HEARTBEAT_SUCCESS, SEND_STORED_VOTES], sendCachedVotes);
  yield fork(voteActionHandler);
}

export function* languageSaga() {
  let currentQuestion = yield select(getCurrentQuestion);
  if (currentQuestion.languages.length < 2) {
    return;
  }

  while (true) {
    try {
      let { stop } = yield race({
        loop: delay(6000),
        stop: take([PAUSE_LANGUAGE_ROTATION, RESET_KIOSK]),
      });

      if (stop) {
        // we will stop if we get a 'PAUSE' command, or
        // if the kiosk is resetting.
        // we'll get a 'START' command later to resume
        return;
      }

      // show next language
      yield put(nextLanguage());
    } catch (err) {
      //
    }
  }
}

function* fetchQuestionData() {
  try {
    yield put(log("fetching question data"));
    let source = CancelToken.source();

    let { question, timeout } = yield race({
      question: call(callApi, {
        url: "/question/",
        method: "get",
        cancelToken: source.token,
      }),
      timeout: delay(15000),
    });

    if (timeout) {
      source.cancel();
      yield put(log("question fetch exceeded 15s timeout"));
      return;
    }

    yield put(setQuestionData(question));
    yield put(startLanguageRotation());

    yield put(log("question updated"));
  } catch (err) {
    yield put(log("fetching question error", err.toString()));
    yield put(questionError(err));
  }
}

// TODO: pull this value from config
// how long before we bail on the second level and take a non-specific vote
let SPECIFIC_QUESTION_TIMEOUT_DELAY = 8 * 1000; // 8 seconds

function* voteActionHandler() {
  try {
    let chan: Channel<VotePayload> = yield call(channel, buffers.expanding(10));

    /* start a worker to send vote data */
    yield fork(sendVotes, chan);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { payload: vote }: VoteTappedAction = yield take([
        VOTE_BUTTON_TAPPED,
      ]);
      // keep the current language selected
      yield put(pauseLanguageRotation());

      const { locationId, questionId } = yield select(
        getCurrentLocationAndQuestion
      );
      const language = yield select(getCurrentLanguage);

      const votePayload: VotePayload = {
        location_id: locationId,
        question_id: questionId,
        language,
        timestamp: vote.timestamp,
        time_string: vote.time_string,
        vote_value: vote.answer_value,
      };

      // need a string value to lookup answer config
      const hasSpecifics = yield select(answerHasSpecifics, vote.answer_id);

      if (hasSpecifics) {
        yield put(showSecondaryAnswers(vote.answer_id));

        let { second_level_vote } = yield race({
          second_level_vote: take([VOTE_BUTTON_TAPPED]),
          timeout: delay(SPECIFIC_QUESTION_TIMEOUT_DELAY),
        });

        if (second_level_vote) {
          votePayload.specific_value = second_level_vote.payload.answer_value;

          // wait for the second level animation
          yield delay(700);
        }
      }

      yield put(chan, votePayload); // put the vote into the channel for processing
      yield delay(200); // wait for our confirmation text to fully display
      yield put(voteSubmit()); // this action is poorly named -- this action resets the button panel
      yield put(startLanguageRotation()); // start cycling languages again
    }
  } catch (e) {
    console.log("ERROR", e); //eslint-disable-line no-console
  }
}

function* sendVotes(channel: Channel<VotePayload>) {
  let consecutiveFails = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (consecutiveFails > 2) {
      yield put(log("Flushing all votes in channel to storage for later"));

      let votes: VotePayload[] = yield flush(channel);
      yield put(storeVotes(votes));

      consecutiveFails = 0;
    }

    let voteData: VotePayload = yield take(channel);

    try {
      let source = CancelToken.source();

      let { timeout } = yield race({
        results: call(callApi, {
          url: "/vote/",
          cancelToken: source.token,
          data: [voteData], // always send votes in an array, even if it's only 1
        }),
        timeout: delay(3000),
      });

      if (timeout) {
        source.cancel();

        yield put(voteFailure(voteData));
        consecutiveFails++;
        continue;
      }

      consecutiveFails = 0;
      yield put(voteSuccess());
    } catch (e) {
      yield put(voteFailure(voteData));
      yield put(log("Failed to post vote, storing"));
      consecutiveFails++;
    }
  }
}

function* sendCachedVotes() {
  try {
    let cachedVotes: VotePayload[] = yield select(getCachedVotes);

    if (cachedVotes.length > 0) {
      let source = CancelToken.source();

      let { timeout } = yield race({
        response: call(callApi, {
          url: "/vote/",
          cancelToken: source.token,
          data: cachedVotes,
        }),
        timeout: delay(10000),
      });

      if (timeout) {
        source.cancel();
        return;
      }

      yield put(cachedVotesSubmitted());
    } else {
      //console.log('No cached votes') //eslint-disable-line no-console
    }
  } catch (e) {
    console.error("Sending cached votes failure", e); //eslint-disable-line no-console
  }
}
