export interface LogEntry {
  message: string;
  timestamp: string;
  context?: any;
}

export interface LogState {
  entries: LogEntry[];
}

export const ADD_LOG_ENTRY = "logs/add";
export const CLEAR_LOG = "logs/clear";

interface LogAction {
  type: typeof ADD_LOG_ENTRY;
  payload: LogEntry;
}

interface ClearLogsAction {
  type: typeof CLEAR_LOG;
  payload: LogEntry;
}

export type LogActionTypes = LogAction | ClearLogsAction;
