import React from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";

import conf from "./config";

import { fetchPIN } from "./auth/actions";
import { openMenu } from "./actions/kiosk";

import { AuthState } from "./auth/types";
import { QuestionState } from "./question/types";
import ActivationScreen from "./components/ActivationScreen";
import AdminMenu from "./components/AdminMenu";
import Kiosk from "./Kiosk";
import KioskLoader from "./components/KioskLoader";
import Footer from "./components/Footer";

interface RouterProps {
  refreshPin: () => void;
  openMenu: () => void;
  isAuthenticated: boolean;
  pinCode: string | null;
  kiosk: any;
  question: QuestionState;
}

function Router({
  refreshPin,
  openMenu,
  isAuthenticated,
  pinCode,
  kiosk,
  question: { theme },
}: RouterProps) {
  return (
    <div
      id="kiosk"
      style={{
        backgroundColor: theme.backgroundColor || "#FFFFFF",
      }}
    >
      {kiosk.isLoading ? (
        <KioskLoader />
      ) : !isAuthenticated ? (
        <ActivationScreen
          envURL={conf.apiUrl}
          pinCode={pinCode}
          onRefreshPIN={refreshPin}
        />
      ) : kiosk.showingMenu ? (
        <AdminMenu />
      ) : (
        <Kiosk />
      )}

      {!kiosk.showingMenu && (
        <Footer
          isAuthenticated={isAuthenticated}
          onHold={openMenu}
          isPoweredBy={theme.isPoweredBy || false}
          brandLogo={theme.brandLogo || undefined}
          tapynessLogoColor={theme.tapynessLogoForegroundColor}
          tapynessLogoBackgroundColor={theme.tapynessLogoBackgroundColor}
        />
      )}
    </div>
  );
}

function mapState({
  auth,
  kiosk,
  question,
}: {
  auth: AuthState;
  kiosk: any;
  question: QuestionState;
}) {
  return {
    isAuthenticated: !!auth.accessToken,
    pinCode: auth.pinCode,
    kiosk,
    question,
  };
}

const mapDispatch = (dispatch: Dispatch) => ({
  refreshPin: () => dispatch(fetchPIN()),
  openMenu: () => dispatch(openMenu()),
});

export default connect(mapState, mapDispatch)(Router);
