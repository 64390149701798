import {
  OPEN_MENU,
  CLOSE_MENU,
  MENU_AUTH_ATTEMPT,
  MENU_AUTH_SUCCESS,
  MENU_AUTH_FAILURE,
  RESET_KIOSK,
  UPDATE_CHECK,
  UPDATE_STATUS,
  SERVICE_WORKER_READY,
  UPDATE_SERVICE_WORKER_STATUS,
  /*
   *UPDATE_KIOSK_META,
   */
} from "../actionTypes";

let openMenu = () => ({
  type: OPEN_MENU,
  payload: {},
});

let closeMenu = () => ({
  type: CLOSE_MENU,
  payload: {},
});

let authFailure = () => ({
  type: MENU_AUTH_FAILURE,
  payload: {},
});

let authSuccess = () => ({
  type: MENU_AUTH_SUCCESS,
  payload: {},
});

let attemptAuth = (code) => ({
  type: MENU_AUTH_ATTEMPT,
  payload: {
    code: code,
  },
});

let resetKiosk = () => ({
  type: RESET_KIOSK,
  payload: {},
});

let checkForUpdate = () => ({
  type: UPDATE_CHECK,
  payload: {},
});

let cacheStatusUpdate = (e) => ({
  type: UPDATE_STATUS,
  payload: {
    status: e,
  },
});

const serviceWorkerReady = () => ({
  type: SERVICE_WORKER_READY,
  payload: {},
});

/**
 * @param {ServiceWorkerRegistration} registration
 */
const updateServiceWorkerStatus = (registration) => ({
  type: UPDATE_SERVICE_WORKER_STATUS,
  payload: {
    registration,
  },
});

/*
 *let setKioskMeta = ({ kioskId }) => ({
 *    type: UPDATE_KIOSK_META,
 *    payload: { kioskId },
 *})
 */

export {
  openMenu,
  closeMenu,
  authFailure,
  authSuccess,
  attemptAuth,
  resetKiosk,
  checkForUpdate,
  cacheStatusUpdate,
  serviceWorkerReady,
  updateServiceWorkerStatus,
};
