import React from "react";
import { ReactComponent as AvatarLogo } from "../../../img/square-logo.svg";

const KioskLoader: React.FC = () => (
  <div id="loading" className="animate">
    <AvatarLogo />
  </div>
);

export default KioskLoader;
export { KioskLoader };
