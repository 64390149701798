import React from "react";

interface SquareTapynessLogoProps {
  size?: string;
  color?: string;
  backgroundColor?: string;
}

function SquareTapynessLogo({
  size = "100px",
  color = "#07B9FE",
  backgroundColor = "#FFFFFF",
}: SquareTapynessLogoProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <defs></defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Square-Logo" fillRule="nonzero">
          <g id="square-logo2">
            <rect
              id="Rectangle-path"
              fill={color}
              x="0"
              y="0"
              width="100"
              height="100"
            ></rect>
            <g
              id="Group"
              transform="translate(12.500000, 19.750000)"
              fill={backgroundColor}
            >
              <path
                d="M11.25,13.1666667 L12.5,14.8333333 C14.7916667,12.9583333 17.0833333,12.125 20.2083333,12.125 C23.3333333,12.125 25.625,12.9583333 27.9166667,14.8333333 L29.1666667,13.1666667 C26.4583333,10.4583333 23.5416667,9.20833333 20,9.20833333 C16.4583333,9.20833333 13.9583333,10.4583333 11.25,13.1666667 Z"
                id="Shape"
              ></path>
              <polygon
                id="Shape"
                points="35.8333333 44.625 39.7916667 44.625 39.7916667 3.79166667 53.75 3.79166667 53.75 0.0416666667 35.8333333 0.0416666667 35.8333333 4"
              ></polygon>
              <path
                d="M47.0833333,9.83333333 C45.625,9.83333333 44.375,11.0833333 44.375,12.5416667 C44.375,14 45.625,15.25 47.0833333,15.25 C48.5416667,15.25 49.7916667,14 49.7916667,12.5416667 C49.7916667,11.0833333 48.5416667,9.83333333 47.0833333,9.83333333 Z"
                id="Shape"
              ></path>
              <path
                d="M0.833333333,34.8333333 C6.66666667,49.8333333 21.6666667,60.4583333 37.5,60.4583333 C53.3333333,60.4583333 68.3333333,50.0416667 74.1666667,34.8333333 L70.625,33.5833333 C65.4166667,47.125 51.875,56.7083333 37.5,56.7083333 C23.125,56.7083333 9.58333333,47.125 4.375,33.5833333 L0.833333333,34.8333333 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SquareTapynessLogo;
