import React from "react";

interface TapynessLogoProps {
  height?: string | number;
  width?: string | number;
  color?: string;
  backgroundColor?: string;
}

function TapynessLogo({
  width = "247px",
  height = "60px",
  color = "#07B9FE",
  backgroundColor = "#FFFFFF",
}: TapynessLogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 247 60">
      <defs>
        <polygon
          id="tapyness-logo-a"
          points="133.053 .92 133.053 22.908 116.987 22.908 116.987 .92 133.053 .92"
        />
        <polygon
          id="tapyness-logo-c"
          points="11.112 .374 .103 .374 .103 27.553 11.112 27.553 11.112 .374"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <polygon
          fill={color}
          points="83.574 22.235 76.871 22.235 76.871 40.637 73.165 40.637 73.165 22.235 66.52 22.235 66.52 19.236 83.574 19.236"
        />
        <path
          fill={color}
          d="M93.9133191,32.6558792 L100.144383,32.6558792 L97.0282128,23.7769936 L93.9133191,32.6558792 Z M101.193745,35.6536309 L92.865234,35.6536309 L91.1226809,40.6363289 L87.2392766,40.6363289 L95.3597021,19.2352536 L98.7120426,19.2352536 L106.849064,40.6363289 L102.95034,40.6363289 L101.193745,35.6536309 Z"
        />
        <g transform="translate(113 18.022)">
          <path
            fill={color}
            d="M4.55714894,11.6789682 L9.04565957,11.6789682 C10.3745957,11.6789682 11.3882128,11.367629 12.0877872,10.7462214 C12.7860851,10.123543 13.1358723,9.22383621 13.1358723,8.04837189 C13.1358723,6.89196915 12.7822553,5.96811774 12.0724681,5.27808841 C11.3639574,4.58678831 10.3886383,4.23224286 9.14906383,4.21318128 L4.55714894,4.21318128 L4.55714894,11.6789682 Z M4.55714894,14.6779907 L4.55714894,22.6139633 L0.820553191,22.6139633 L0.820553191,1.21415879 L9.04565957,1.21415879 C11.4469362,1.21415879 13.3541702,1.83556642 14.7686383,3.08219398 C16.1805532,4.32755078 16.8865106,5.97701314 16.8865106,8.02549799 C16.8865106,10.1260845 16.1958723,11.7590268 14.8120426,12.9268665 C13.4294894,14.0934354 11.491617,14.6779907 9.00097872,14.6779907 L4.55714894,14.6779907 Z"
          />
          <polygon
            fill={color}
            points="31.7 11.356 36.647 1.214 40.781 1.214 33.576 14.737 33.576 22.615 29.824 22.615 29.824 14.737 22.605 1.214 26.754 1.214"
          />
          <polygon
            fill={color}
            points="64.706 22.615 60.97 22.615 51.386 7.431 51.386 22.615 47.651 22.615 47.651 1.214 51.386 1.214 61 16.456 61 1.214 64.706 1.214"
          />
          <polygon
            fill={color}
            points="86.223 13.061 77.392 13.061 77.392 19.617 87.714 19.617 87.714 22.614 73.657 22.614 73.657 1.213 87.611 1.213 87.611 4.212 77.392 4.212 77.392 10.122 86.223 10.122"
          />
          <path
            fill={color}
            d="M106.528426,17.1032595 C106.528426,16.162888 106.196511,15.4360063 105.532681,14.9276974 C104.867574,14.4181177 103.668851,13.903455 101.936511,13.3837091 C100.20417,12.8652341 98.8267234,12.2870327 97.801617,11.649105 C95.8433191,10.4253514 94.8628936,8.82671989 94.8628936,6.85702292 C94.8628936,5.13258499 95.5701277,3.71186163 96.9820426,2.59485283 C98.395234,1.47784403 100.228426,0.919975019 102.482894,0.919975019 C103.979064,0.919975019 105.313106,1.19319105 106.485021,1.74216466 C107.65566,2.29113826 108.576085,3.07266319 109.246298,4.08673944 C109.915234,5.10208646 110.249702,6.22544911 110.249702,7.46063973 L106.528426,7.46063973 C106.528426,6.35252634 106.177362,5.4845889 105.472681,4.85809819 C104.769277,4.23160747 103.762043,3.91772673 102.454809,3.91772673 C101.233106,3.91772673 100.284596,4.17696427 99.6105532,4.69416857 C98.9377872,5.21137287 98.5994894,5.9331715 98.5994894,6.86083523 C98.5994894,7.64108939 98.9633191,8.29299555 99.6922553,8.81528294 C100.421191,9.33629955 101.621191,9.84460845 103.296085,10.3363973 C104.968426,10.8294569 106.311404,11.392409 107.326298,12.0239828 C108.339915,12.6568274 109.082894,13.3811676 109.555234,14.1995449 C110.028851,15.0179222 110.265021,15.9748137 110.265021,17.0740317 C110.265021,18.8569252 109.578213,20.275107 108.204596,21.3285772 C106.830979,22.3820474 104.968426,22.9081471 102.616936,22.9081471 C101.060766,22.9081471 99.6309787,22.6222233 98.3262979,22.049105 C97.0228936,21.474716 96.008,20.6842956 95.2841702,19.6753025 C94.561617,18.6650386 94.1990638,17.4895742 94.1990638,16.1476388 L97.9356596,16.1476388 C97.9356596,17.362497 98.3390638,18.3028685 99.1458723,18.9700239 C99.9539574,19.6359085 101.110553,19.9688509 102.616936,19.9688509 C103.915234,19.9688509 104.893106,19.7070718 105.546723,19.1835136 C106.201617,18.6586847 106.528426,17.9648431 106.528426,17.1032595"
          />
          <mask id="tapyness-logo-b" fill="#FFFFFF">
            <use xlinkHref="#tapyness-logo-a" />
          </mask>
          <path
            fill={color}
            d="M129.316298,17.1032595 C129.316298,16.162888 128.984383,15.4360063 128.320553,14.9276974 C127.655447,14.4181177 126.456723,13.903455 124.723106,13.3837091 C122.992043,12.8652341 121.613319,12.2870327 120.589489,11.649105 C118.631191,10.4253514 117.650766,8.82671989 117.650766,6.85702292 C117.650766,5.13258499 118.356723,3.71186163 119.769915,2.59485283 C121.183106,1.47784403 123.015021,0.919975019 125.270766,0.919975019 C126.766936,0.919975019 128.100979,1.19319105 129.272894,1.74216466 C130.443532,2.29113826 131.363957,3.07266319 132.03417,4.08673944 C132.70183,5.10208646 133.037574,6.22544911 133.037574,7.46063973 L129.316298,7.46063973 C129.316298,6.35252634 128.965234,5.4845889 128.260553,4.85809819 C127.555872,4.23160747 126.549915,3.91772673 125.241404,3.91772673 C124.020979,3.91772673 123.072468,4.17696427 122.398426,4.69416857 C121.724383,5.21137287 121.387362,5.9331715 121.387362,6.86083523 C121.387362,7.64108939 121.751191,8.29299555 122.480128,8.81528294 C123.209064,9.33629955 124.409064,9.84460845 126.082681,10.3363973 C127.756298,10.8294569 129.099277,11.392409 130.11417,12.0239828 C131.127787,12.6568274 131.870766,13.3811676 132.343106,14.1995449 C132.815447,15.0179222 133.052894,15.9748137 133.052894,17.0740317 C133.052894,18.8569252 132.366085,20.275107 130.991191,21.3285772 C129.618851,22.3820474 127.756298,22.9081471 125.403532,22.9081471 C123.848638,22.9081471 122.418851,22.6222233 121.11417,22.049105 C119.809489,21.474716 118.795872,20.6842956 118.072043,19.6753025 C117.348213,18.6650386 116.986936,17.4895742 116.986936,16.1476388 L120.723532,16.1476388 C120.723532,17.362497 121.12566,18.3028685 121.933745,18.9700239 C122.740553,19.6359085 123.897149,19.9688509 125.403532,19.9688509 C126.703106,19.9688509 127.679702,19.7070718 128.333319,19.1835136 C128.989489,18.6586847 129.316298,17.9648431 129.316298,17.1032595"
            mask="url(#tapyness-logo-b)"
          />
        </g>
        <rect width="60" height="60" fill={color} />
        <g transform="translate(7 11.022)">
          <path
            fill={backgroundColor}
            d="M6.43046809,8.43768763 L7.25642553,9.46193005 C8.676,8.30298577 10.0917447,7.8073846 11.988766,7.8073846 C13.8870638,7.8073846 15.3028085,8.30298577 16.722383,9.46193005 L17.5483404,8.43768763 C15.9513191,6.79712067 14.1334468,6.00034648 11.988766,6.00034648 C9.8453617,6.00034648 8.02621277,6.79712067 6.43046809,8.43768763"
          />
          <g transform="translate(21.301)">
            <mask id="tapyness-logo-d" fill="#FFFFFF">
              <use xlinkHref="#tapyness-logo-c" />
            </mask>
            <polygon
              fill={backgroundColor}
              points=".103 27.553 2.533 27.553 2.533 2.639 11.112 2.639 11.112 .373 .114 .373 .103 2.849"
              mask="url(#tapyness-logo-d)"
            />
          </g>
          <path
            fill={backgroundColor}
            d="M28.2884681 6.34599652C27.3718723 6.34599652 26.6250638 7.08812751 26.6250638 8.00181275 26.6250638 8.91422722 27.3718723 9.65762898 28.2884681 9.65762898 29.2050638 9.65762898 29.9518723 8.91422722 29.9518723 8.00181275 29.9518723 7.08812751 29.2050638 6.34599652 28.2884681 6.34599652M.000127659574 21.642409C3.55161702 30.8504247 12.7290638 37.2474921 22.451617 37.2474921 32.1767234 37.2474921 41.3541702 30.8504247 44.9056596 21.642409L42.7214043 20.8481764C39.4992766 29.1602976 31.2192766 34.9334159 22.451617 34.9334159 13.685234 34.9334159 5.40651064 29.1602976 2.18310638 20.8481764L.000127659574 21.642409z"
          />
        </g>
      </g>
    </svg>
  );
}

export default TapynessLogo;
