import React from "react";

const Checkmark: React.FC = () => (
  <svg className="confirmation checkmark" style={{ stroke: "currentColor" }}>
    <path d="m31.5,46.5l15.3,-23.2" />
    <path d="m31.5,46.5l-8.5,-7.1" />
  </svg>
);

export default Checkmark;
