import React from "react";
import cx from "classnames";

type ButtonProps = {
  variant?: "default" | "primary" | "secondary" | "destructive";
  disabled?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  text: string;
  style?: React.CSSProperties;
};

const Button: React.FC<ButtonProps> = ({
  variant = "default",
  disabled = false,
  style,
  text,
  onClick,
}) => {
  let classes = cx("adminbtn", "button", {
    "button--primary": variant === "primary",
    "button--secondary": variant === "secondary",
    "button--destructive": variant === "destructive",
  });

  return (
    <button
      className={classes}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
