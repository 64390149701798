import { put, select, call, take } from "redux-saga/effects";

import { isAuthenticated, hasQuestion } from "../selectors";
import { log } from "../logs/actions";

import { resetKiosk } from "../actions/kiosk";
import { fetchPIN } from "../auth/actions";
import { getQuestion } from "../actions/question";
import { startHeartbeat } from "../heartbeats/actions";
import { getKioskConfig } from "../device/sagas";
import { startLanguageRotation } from "../question/actions";

import { TOKENS_SUCCESS, QUESTION_SUCCESS } from "../actionTypes";

function* deviceFlowSaga() {
  try {
    let isAlreadyAuthenticated = yield select(isAuthenticated);

    if (!isAlreadyAuthenticated) {
      yield put(fetchPIN());
      yield take([TOKENS_SUCCESS]);

      yield call(getKioskConfig);
    }

    yield put(startHeartbeat());

    let hasQuestionDownloaded = yield select(hasQuestion);
    if (!hasQuestionDownloaded) {
      yield put(getQuestion());
      yield take([QUESTION_SUCCESS]);
    } else {
      yield put(startLanguageRotation());
    }
  } catch (err) {
    yield put(log("ERROR starting kiosk", err.toString()));

    // eslint-disable-next-line no-console
    console.log("ERROR starting kiosk", err.toString());

    yield put(resetKiosk());
  }
}

export default deviceFlowSaga;
