import React, { useCallback, useEffect } from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";

import QRCode from "qrcode.react";

import conf from "../../config";
import LogoFlag from "../LogoFlag";
import Button from "./Button";
import PinInput from "./PinInput";
import DebugBlock from "./DebugBlock";

import { fetchPIN } from "../../auth/actions";
import { resetKiosk, attemptAuth, closeMenu } from "../../actions/kiosk";
import { getQuestion, sendStoredVotes } from "../../actions/question";
import { RootState } from "../../store/configureStore";

declare module "qrcode.react" {
  interface QRCodeProps {
    includeMargin: boolean;
  }
}

function getSwStatus(
  ready: boolean,
  serviceWorkerRegistration: ServiceWorkerRegistration | null
) {
  if (!ready) return "inactive";

  if (serviceWorkerRegistration?.installing) {
    return "installing";
  }

  if (serviceWorkerRegistration?.waiting) {
    return "waiting";
  }

  return ready ? "active" : "inactive";
}

interface Props {
  device: RootState["device"];
  kiosk: RootState["kiosk"];
  question: RootState["question"];
  refreshConfig: () => void;
  attemptAuth: (code: string) => void;
  closeMenu: () => void;
  sendStoredVotes: () => void;
  resetKiosk: () => void;
}

function AdminMenu({
  device,
  kiosk,
  question,
  refreshConfig,
  attemptAuth,
  closeMenu,
  sendStoredVotes,
  resetKiosk,
}: Props) {
  const { kioskUuid } = device;
  const { serviceWorkerReady, serviceWorkerRegistration } = kiosk;

  const serviceWorkerState = getSwStatus(
    serviceWorkerReady,
    serviceWorkerRegistration
  );

  // if we have a waiting SW, this event will fire letting us know
  // that it's ready to take over and we can reload the page
  useEffect(() => {
    try {
      if (serviceWorkerRegistration?.waiting) {
        function handleStateChange(
          event: ServiceWorkerEventMap["statechange"]
        ) {
          if (
            event.target &&
            "state" in event.target &&
            ((event.target as any).state as string) === "activated"
          ) {
            window.location.reload();
          }
        }

        const waitingSw = serviceWorkerRegistration.waiting;
        waitingSw.addEventListener("statechange", handleStateChange);

        return () => {
          waitingSw.removeEventListener("statechange", handleStateChange);
        };
      }
    } catch (err) {
      console.error(err);
    }
  }, [serviceWorkerRegistration]);

  // Used to trigger the swap from waiting -> activated state
  const updateServiceWorker = useCallback(() => {
    try {
      if (!serviceWorkerRegistration?.waiting) return null;

      serviceWorkerRegistration?.waiting.postMessage({ type: "SKIP_WAITING" });
    } catch (err) {
      console.error(err);
    }
  }, [serviceWorkerRegistration]);

  function renderSendVotesButton() {
    let votes = question.cachedVotes.length;
    let title = "No Cached Votes";

    if (votes > 1) {
      title = `Force Send ${votes} Votes`;
    } else if (votes === 1) {
      title = "Force Send 1 Vote";
    }

    return (
      <Button text={title} disabled={votes < 1} onClick={sendStoredVotes} />
    );
  }

  if (!kiosk.menuAuthenticated) {
    return (
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          padding: "2rem",
        }}
      >
        <PinInput
          title="Enter PIN"
          inputPlaceholder="ABC123"
          attemptAuth={attemptAuth}
        />

        <div
          style={{
            margin: "5rem auto",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <QRCode
            includeMargin={true}
            size={64}
            level="M"
            value={`${conf.apiUrl}/kiosks/${device.kioskId}`}
          />
          <p
            style={{
              margin: "0 0 0 1rem",
              color: "grey",
              textAlign: "left",
            }}
          >
            {`kiosk id: ${kioskUuid}`}
            <br />
            {`version: ${conf.version}`}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-menu">
      <div className="header">
        <LogoFlag size="60px" text="Kiosk Admin Menu" />
        <Button variant="primary" text="Close" onClick={closeMenu} />
      </div>

      <div className="content">
        <div className="debug-info">
          <div>
            <strong>Debug/Support information</strong>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <QRCode
              includeMargin={true}
              size={64}
              level="L"
              bgColor="transparent"
              value={`${conf.apiUrl}/kiosks/${device.kioskId}`}
            />
            <p
              style={{
                margin: "0 0 0 0.5rem",
                color: "grey",
                textAlign: "left",
                fontSize: "0.7rem",
              }}
            >
              {`kiosk id: ${kioskUuid}`}
              <br />
              {`version: ${conf.version}`}
            </p>
          </div>

          <DebugBlock title="ENV">
            {JSON.stringify(window.ENV, null, 2)}
          </DebugBlock>
          <DebugBlock title="store.device">
            {JSON.stringify(device, null, 2)}
          </DebugBlock>
          <DebugBlock title="store.kiosk">
            {JSON.stringify(kiosk, null, 2)}
          </DebugBlock>
          <DebugBlock title="store.question">
            {JSON.stringify(question, null, 2)}
          </DebugBlock>
        </div>

        <div className="actions">
          <div>
            <strong>Actions</strong>
          </div>
          <div>
            {serviceWorkerState === "waiting" && (
              <Button text="Install and Reload" onClick={updateServiceWorker} />
            )}
            {serviceWorkerState === "active"
              ? "Service worker installed and ready"
              : serviceWorkerState === "inactive"
              ? "Service worker not installed"
              : serviceWorkerState === "installing"
              ? "Service worker downloading update"
              : "Service worker update ready"}
          </div>
          <div>
            <Button text="Redownload configuration" onClick={refreshConfig} />
            <div style={{ fontSize: "18px" }}>
              Redownload question/theme configuration
            </div>
          </div>
          <div>{renderSendVotesButton()}</div>
          <hr style={{ width: "100%" }} />
          <div>
            <Button
              text="Reset Kiosk"
              variant="destructive"
              onClick={resetKiosk}
            />
            <p
              style={{
                fontSize: "18px",
                margin: "0.5rem 0 0 0",
              }}
            >
              {`If you're having issues with this kiosk or need
                                    to change the question, please Reset.`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapState(state: RootState) {
  return {
    device: state.device,
    kiosk: state.kiosk,
    question: state.question,
  };
}

function mapDispatch(dispatch: Dispatch) {
  return {
    refreshConfig: () => {
      dispatch(getQuestion());
      // get device config here too?
    },
    attemptAuth: (code: string) => dispatch(attemptAuth(code)),
    closeMenu: () => dispatch(closeMenu()),
    sendStoredVotes: () => dispatch(sendStoredVotes()),
    resetKiosk: () => {
      dispatch(resetKiosk());
      dispatch(fetchPIN());
    },
  };
}

export default connect(mapState, mapDispatch)(AdminMenu);
