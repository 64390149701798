import { ADD_LOG_ENTRY, CLEAR_LOG, LogActionTypes } from "./types";

function log(message: string, context?: any): LogActionTypes {
  let now = new Date();

  if (process.env.NODE_ENV !== "production") {
    console.log(now, message, context); // eslint-disable-line no-console
  }

  return {
    type: ADD_LOG_ENTRY,
    payload: {
      message,
      context,
      timestamp: now.toJSON(),
    },
  };
}

function clearLog(): LogActionTypes {
  let now = new Date();

  return {
    type: CLEAR_LOG,
    payload: {
      message: "Logs reset",
      timestamp: now.toJSON(),
    },
  };
}

export { log, clearLog };
