import { DeviceConfigState } from "./types";

export const getCurrentLocationAndQuestion = ({
  device,
}: {
  device: DeviceConfigState;
}) => ({
  locationId: device.locationId,
  questionId: device.questionId,
});
