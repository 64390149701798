import {
  call,
  delay,
  put,
  take,
  race,
  select,
  takeLatest,
} from "redux-saga/effects";

import { OPEN_MENU, MENU_AUTH_ATTEMPT } from "../actionTypes";
import {
  openMenu,
  closeMenu,
  authSuccess,
  authFailure,
} from "../actions/kiosk";
import { getPinCode } from "../selectors";

export function* menuSaga() {
  yield takeLatest([MENU_AUTH_ATTEMPT], authenticate);

  /* eslint-disable no-constant-condition */
  while (true) {
    try {
      yield take(OPEN_MENU);
      yield call(showMenu);
    } catch (err) {
      /* */
    }
  }
  /* eslint-enable no-constant-condition */
}

function* authenticate({ payload }) {
  try {
    let pin = yield select(getPinCode);
    let code = payload.code.trim().toUpperCase();

    if (pin === code) {
      yield put(authSuccess());
      return;
    }

    yield put(authFailure());
    return;
  } catch (err) {
    /* */
  }
}

function* showMenu() {
  try {
    yield put(openMenu());

    let { timeout } = yield race({
      auth: take([MENU_AUTH_ATTEMPT]),
      timeout: delay(60000), // 60 seconds
    });

    if (timeout) {
      yield put(closeMenu());
    }
  } catch (err) {
    /* */
  }
}
