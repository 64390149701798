export const SHOW_SPECIFIC_ANSWERS = "question/specific/show";

export const VOTE_BUTTON_TAPPED = "votes/tapped";
export const VOTE_SUBMIT = "votes/submit";
export const VOTE_SUCCESS = "votes/success";
export const VOTE_FAILURE = "votes/fail";

export const STORE_VOTES = "votes/store";
export const SEND_STORED_VOTES = "votes/stored/send";

export const CACHED_VOTES_SUBMIT_SUCCESS = "votes/stored/send/success";

export const QUESTION_REQUESTED = "question/fetching";
export const QUESTION_SUCCESS = "question/success";
export const QUESTION_ERROR = "question/fail";

export const NEXT_LANGUAGE = "question/language/next";
export const START_LANGUAGE_ROTATION = "question/language/start";
export const PAUSE_LANGUAGE_ROTATION = "question/language/stop";

export interface VotePayload {
  location_id: number;
  question_id: number;
  language: string;
  timestamp: number;
  time_string: string;
  vote_value: number;
  specific_value?: number | null;
}

interface StartLanguageRotationAction {
  type: typeof START_LANGUAGE_ROTATION;
}
interface StopLanguageRotationAction {
  type: typeof PAUSE_LANGUAGE_ROTATION;
}
interface NextLanguageAction {
  type: typeof NEXT_LANGUAGE;
}

interface FetchQuestionAction {
  type: typeof QUESTION_REQUESTED;
}

export interface QuestionConfig {
  questionId: number;
  locationLogo: string | null;
  languages: string[];
  questions: any;
  answers: AnswersConfig;
  theme: ThemeConfig;
}
interface AnswersConfig {}
interface ThemeConfig {}

interface SetQuestionDataAction {
  type: typeof QUESTION_SUCCESS;
  payload: QuestionConfig;
}

interface QuestionErrorAction {
  type: typeof QUESTION_ERROR;
  payload: {
    error: Error;
  };
}

interface CachedVotesSubmittedAction {
  type: typeof CACHED_VOTES_SUBMIT_SUCCESS;
}

interface StoreVotesAction {
  type: typeof STORE_VOTES;
  payload: {
    votes: VotePayload[];
  };
}

interface SendStoredVotesAction {
  type: typeof SEND_STORED_VOTES;
}

interface VoteSubmitAction {
  type: typeof VOTE_SUBMIT;
}

interface VoteSuccessAction {
  type: typeof VOTE_SUCCESS;
}

interface VoteFailureAction {
  type: typeof VOTE_FAILURE;
  payload: VotePayload;
}

interface ShowSecondaryAnswersAction {
  type: typeof SHOW_SPECIFIC_ANSWERS;
  payload: {
    answer_id: string;
  };
}

export interface VoteTappedAction {
  type: typeof VOTE_BUTTON_TAPPED;
  payload: {
    answer_id: string;
    answer_value: number;
    timestamp: number;
    time_string: string;
  };
}

export interface QuestionState {
  questionId: number | null;
  locationLogo: string | null;
  languages: string[];
  questions: any;
  answers: any;
  theme: any;
  activeAnswer: string;
  currentLangIdx: number;
  cachedVotes: VotePayload[];
}

export type QuestionActionTypes =
  | StartLanguageRotationAction
  | StopLanguageRotationAction
  | NextLanguageAction
  | FetchQuestionAction
  | SetQuestionDataAction
  | QuestionErrorAction
  | CachedVotesSubmittedAction
  | StoreVotesAction
  | SendStoredVotesAction
  | VoteSubmitAction
  | VoteSuccessAction
  | VoteFailureAction
  | ShowSecondaryAnswersAction
  | VoteTappedAction;
