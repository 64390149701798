import React from "react";

import LogoFlag from "../LogoFlag";
import Button from "./Button";

type InputProps = {
  title: string;
  inputPlaceholder: string;
  attemptAuth: (code: string) => void;
};
type InputState = {
  value: string;
};

class PinInput extends React.Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props);

    this.state = {
      value: "",
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onInputChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({ value: e.currentTarget.value });
  }

  handleSubmit(e: React.SyntheticEvent<HTMLFormElement>): void {
    e.preventDefault();
    this.props.attemptAuth(this.state.value);
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          margin: "0 auto",
          padding: "0.5rem",
          textAlign: "center",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <LogoFlag text="Kiosk Admin" />

        <form onSubmit={this.handleSubmit}>
          <input
            className="pin-input"
            type="text"
            onChange={this.onInputChange}
            placeholder={this.props.inputPlaceholder}
            autoComplete="off"
          />

          <Button text="Unlock" variant="primary" />
        </form>
      </div>
    );
  }
}

export default PinInput;
