import React from "react";

import LogoAdminButton from "./LogoAdminButton";
import PoweredByButton from "./PoweredByButton";

type Props = {
  brandLogo?: string | null;
  isPoweredBy: boolean;
  isAuthenticated: boolean;
  onHold: () => void;
  tapynessLogoColor?: string;
  tapynessLogoBackgroundColor?: string;
};

const Footer: React.FC<Props> = ({
  brandLogo,
  onHold,
  isPoweredBy = false,
  isAuthenticated = false,
  tapynessLogoColor,
  tapynessLogoBackgroundColor,
}) => {
  if (!isAuthenticated) {
    return (
      <footer>
        <LogoAdminButton isDisabled />
      </footer>
    );
  }

  return (
    <footer>
      <LogoAdminButton
        brandLogo={brandLogo}
        onHold={onHold}
        logoColor={tapynessLogoColor}
        logoBackgroundColor={tapynessLogoBackgroundColor}
      />
      {isPoweredBy && (
        <PoweredByButton
          onHold={onHold}
          color={tapynessLogoColor}
          backgroundColor={tapynessLogoBackgroundColor}
        />
      )}
    </footer>
  );
};

export default Footer;
