import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  take,
} from "redux-saga/effects";

import { HEARTBEAT_START, HEARTBEAT_STOP, RESET_KIOSK } from "../actionTypes";

import { CancelToken } from "../client";

import config from "../config";
import { callApi } from "../auth";
import { log } from "../logs/actions";

import { heartbeatSuccess } from "./actions";
import { getHeartbeatInterval } from "./selectors";
import { messagesReceived } from "../messages/actions";

function* heartbeatSaga() {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // start the pacemaker once we see the HEARTBEAT START action
    yield take([HEARTBEAT_START]);
    yield put(log("heart started"));
    let pacemakerTask = yield fork(sendHeartbeatSaga);

    // if we see one of these events, stop sending heartbeats
    yield take([HEARTBEAT_STOP, RESET_KIOSK]);
    yield cancel(pacemakerTask);
    yield put(log("heart stopped"));
  }
}

function* sendHeartbeatSaga() {
  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let response;

      try {
        // we use this cancel object to cancel a request once it
        // passes our timeout threshold
        let source = CancelToken.source();
        // race our heartbeat request with a simple sleep function
        let { heartbeat, timeout } = yield race({
          heartbeat: call(callApi, {
            url: "/device/heartbeat/",
            data: {
              timestamp: new Date().toJSON(),
              kiosk_version: config.version,
            },
            cancelToken: source.token,
          }),
          timeout: delay(3000),
        });

        response = heartbeat;

        // if sleep wins, we use the cancel fn to yank the heartbeat request
        if (timeout) {
          source.cancel();
        }
      } catch (err) {
        yield put(log("Heartbeat failure", err.toString()));
      }

      if (response) {
        yield put(heartbeatSuccess(response.recievedAt));

        if (
          "messages" in response &&
          (response.messages.length || typeof response.messages === "object")
        ) {
          yield put(messagesReceived(response.messages));
        }
      }

      let heartbeatInterval = yield select(getHeartbeatInterval);
      yield delay(heartbeatInterval);
    }
  } catch (err) {
    yield put(log("heartbeat error", err.toString()));
  } finally {
    if (yield cancelled()) {
      yield put(log("pacemaker disabled"));
    }
  }
}

export { heartbeatSaga, sendHeartbeatSaga };
