import React from "react";
import cx from "classnames";

import Tappable from "./Tappable";
import BrandLogo from "./BrandLogo";
import TapynessLogo from "./TapynessLogo";

type Props = {
  isDisabled?: boolean;
  onHold?: () => void;
  brandLogo?: string | null;
  logoColor?: string;
  logoBackgroundColor?: string;
};

const TapynessLogoAdminButton: React.FC<Props> = ({
  isDisabled = false,
  brandLogo,
  onHold,
  logoColor,
  logoBackgroundColor,
}) => {
  let [hasFingerDown, setHasFingerDown] = React.useState(false);

  let logoComponent =
    typeof brandLogo !== "undefined" &&
    brandLogo != null &&
    brandLogo.length > 200 ? (
      <BrandLogo data={brandLogo} />
    ) : (
      <TapynessLogo color={logoColor} backgroundColor={logoBackgroundColor} />
    );

  return !isDisabled ? (
    <Tappable
      pressDelay={10000}
      pressMoveThreshold={100}
      onTouchStart={() => {
        setHasFingerDown(true);
      }}
      onPressCancel={() => {
        setHasFingerDown(false);
      }}
      onPress={() => {
        setHasFingerDown(false);
        onHold && onHold();
      }}
      className={cx("svg-container", { "touch-down": hasFingerDown })}
      component="div"
    >
      {logoComponent}
    </Tappable>
  ) : (
    <div className="svg-container">{logoComponent}</div>
  );
};

export default TapynessLogoAdminButton;
