import React, { SyntheticEvent, RefObject } from "react";

const SPACE_KEY = 32;
const ENTER_KEY = 13;

declare global {
  interface Window {
    _blockMouseEvents: boolean;
  }
}

const touchStyles = {
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  cursor: "pointer",
};

interface TouchProps {
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;
}

function objectWithoutKeys(obj: object, keysToRemove: string[]) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (~keysToRemove.indexOf(key)) {
      return acc;
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

function getTouchProps(touch: Touch): undefined | TouchProps {
  if (!touch) return;

  return {
    pageX: touch.pageX,
    pageY: touch.pageY,
    clientX: touch.clientX,
    clientY: touch.clientY,
  };
}

type TappableClasses = {
  active?: string;
  inactive?: string;
};

interface TappableProps {
  component: any; // component to create
  className?: string; // optional className
  classBase: string; // base for generated classNames
  classes?: TappableClasses; // object containing the active and inactive class names
  style?: object; // additional style properties for the component
  disabled?: boolean; // only applies to buttons

  moveThreshold: number; // pixels to move before cancelling tap
  moveXThreshold?: number; // pixels on the x axis to move before cancelling tap (overrides moveThreshold)
  moveYThreshold?: number; // pixels on the y axis to move before cancelling tap (overrides moveThreshold)
  allowReactivation: boolean; // after moving outside of the moveThreshold will you allow
  // reactivation by moving back within the moveThreshold?
  activeDelay: number; // ms to wait before adding the `-active` class
  pressDelay: number; // ms to wait before detecting a press
  pressMoveThreshold: number; // pixels to move before cancelling press
  preventDefault?: boolean; // whether to preventDefault on all events
  stopPropagation?: boolean; // whether to stopPropagation on all events

  onTap?: (
    event: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent | TouchEvent>
  ) => void; // fires when a tap is detected
  onPress?: (
    event: SyntheticEvent<HTMLElement, KeyboardEvent | MouseEvent | TouchEvent>
  ) => void; // fires when a press is detected
  onPressCancel?: () => void;
  onTouchStart?: (
    event: SyntheticEvent<HTMLElement, TouchEvent>
  ) => boolean | void; // pass-through touch event
  onTouchMove?: (event: SyntheticEvent<HTMLElement, TouchEvent>) => void; // pass-through touch event
  onTouchEnd?: (event: SyntheticEvent<HTMLElement, TouchEvent>) => void; // pass-through touch event
  onMouseDown?: (
    event: SyntheticEvent<HTMLElement, MouseEvent>
  ) => boolean | void; // pass-through mouse event
  onMouseUp?: (event: SyntheticEvent<HTMLElement, MouseEvent>) => void; // pass-through mouse event
  onMouseMove?: (event: SyntheticEvent<HTMLElement, MouseEvent>) => void; // pass-through mouse event
  onMouseOut?: (event: SyntheticEvent<HTMLElement, MouseEvent>) => void; // pass-through mouse event
  onKeyDown?: (
    event: SyntheticEvent<HTMLElement, KeyboardEvent>
  ) => boolean | void; // pass-through key event
  onKeyUp?: (event: SyntheticEvent<HTMLElement, KeyboardEvent>) => void; // pass-through key event
  onDeactivate?: () => void;
  onReactivate?: () => void;

  ref?: RefObject<HTMLElement>;
}
type TappableState = {
  isActive: boolean;
  touchActive: boolean;
  pinchActive: boolean;
};

class Tappable extends React.Component<TappableProps, TappableState> {
  private _isMounted: boolean;

  private _activeTimeout?: number;
  private _touchmoveDetectionTimeout?: number;
  private _pressTimeout?: number;

  private _mouseDown?: boolean;
  private _keyDown?: boolean;

  private _initialTouch?: TouchProps;
  private _lastTouch?: TouchProps;
  private _touchmoveTriggeredTimes: number;

  private _scrollPos?: { top: number; left: number };
  private _scrollParents: any[];
  private _scrollParentPos: number[];

  private elementRef: RefObject<HTMLElement>;

  public static defaultProps = {
    component: "span",
    classBase: "Tappable",
    activeDelay: 0,
    allowReactivation: true,
    moveThreshold: 100,
    pressDelay: 1000,
    pressMoveThreshold: 5,
  };

  constructor(props: TappableProps) {
    super(props);

    this.state = {
      isActive: false,
      touchActive: false,
      pinchActive: false,
    };

    this._isMounted = false;
    this._touchmoveTriggeredTimes = 0;
    this._scrollPos = { top: 0, left: 0 };
    this._scrollParents = [];
    this._scrollParentPos = [];

    this.elementRef = React.createRef();

    this.processEvent = this.processEvent.bind(this);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.makeActive = this.makeActive.bind(this);
    this.clearActiveTimeout = this.clearActiveTimeout.bind(this);
    this.initScrollDetection = this.initScrollDetection.bind(this);
    this.initTouchmoveDetection = this.initTouchmoveDetection.bind(this);
    this.cancelTouchmoveDetection = this.cancelTouchmoveDetection.bind(this);
    this.calculateMovement = this.calculateMovement.bind(this);
    this.detectScroll = this.detectScroll.bind(this);
    this.cleanupScrollDetection = this.cleanupScrollDetection.bind(this);
    this.initPressDetection = this.initPressDetection.bind(this);
    this.cancelPressDetection = this.cancelPressDetection.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.endTouch = this.endTouch.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.endMouseEvent = this.endMouseEvent.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.endKeyEvent = this.endKeyEvent.bind(this);
    this.cancelTap = this.cancelTap.bind(this);
    this.handlers = this.handlers.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.cleanupScrollDetection();
    this.cancelPressDetection();
    this.clearActiveTimeout();
  }

  UNSAFE_componentWillUpdate(
    _: Partial<TappableProps>,
    nextState: TappableState
  ) {
    if (this.state.isActive && !nextState.isActive) {
      this.props.onDeactivate && this.props.onDeactivate();
    } else if (!this.state.isActive && nextState.isActive) {
      this.props.onReactivate && this.props.onReactivate();
    }
  }

  processEvent(
    event: SyntheticEvent<HTMLElement, MouseEvent | TouchEvent | KeyboardEvent>
  ) {
    if (this.props.preventDefault) event.preventDefault();
    if (this.props.stopPropagation) event.stopPropagation();
  }

  onTouchStart(event: SyntheticEvent<HTMLElement, TouchEvent>) {
    if (this.props.onTouchStart && this.props.onTouchStart(event) === false)
      return;
    this.processEvent(event);
    window._blockMouseEvents = true;
    if (event.nativeEvent.touches.length === 1) {
      this._initialTouch = this._lastTouch = getTouchProps(
        event.nativeEvent.touches[0]
      );
      this.initScrollDetection();
      this.initPressDetection(event, this.endTouch);
      this.initTouchmoveDetection();

      if (this.props.activeDelay > 0) {
        this._activeTimeout = window.setTimeout(
          this.makeActive,
          this.props.activeDelay
        );
      } else {
        this.makeActive();
      }
    }

    // PINCH SUPPORT....
    //
    // else if (this.onPinchStart &&
    // 		(this.props.onPinchStart || this.props.onPinchMove || this.props.onPinchEnd) &&
    // 		event.touches.length === 2) {
    // 	this.onPinchStart(event);
    // }
  }

  makeActive() {
    if (!this._isMounted) return;
    this.clearActiveTimeout();
    this.setState({
      isActive: true,
    });
  }

  clearActiveTimeout() {
    clearTimeout(this._activeTimeout);
    this._activeTimeout = undefined;
  }

  initScrollDetection() {
    this._scrollPos = { top: 0, left: 0 };
    this._scrollParents = [];
    this._scrollParentPos = [];

    let node = this.elementRef.current;

    while (node) {
      if (
        node.scrollHeight > node.offsetHeight ||
        node.scrollWidth > node.offsetWidth
      ) {
        this._scrollParents.push(node);
        this._scrollParentPos.push(node.scrollTop + node.scrollLeft);
        this._scrollPos.top += node.scrollTop;
        this._scrollPos.left += node.scrollLeft;
      }

      node = node.parentNode as HTMLElement;
    }
  }

  initTouchmoveDetection() {
    this._touchmoveTriggeredTimes = 0;
  }

  cancelTouchmoveDetection() {
    if (this._touchmoveDetectionTimeout) {
      window.clearTimeout(this._touchmoveDetectionTimeout);
      this._touchmoveDetectionTimeout = undefined;
      this._touchmoveTriggeredTimes = 0;
    }
  }

  calculateMovement(touch?: TouchProps) {
    if (this._initialTouch && touch) {
      return {
        x: Math.abs(touch.clientX - this._initialTouch.clientX),
        y: Math.abs(touch.clientY - this._initialTouch.clientY),
      };
    }

    if (!touch) {
      return {
        x: 0,
        y: 0,
      };
    }

    return {
      x: Math.abs(touch.clientX),
      y: Math.abs(touch.clientY),
    };
  }

  detectScroll() {
    var currentScrollPos = { top: 0, left: 0 };
    for (var i = 0; i < this._scrollParents.length; i++) {
      currentScrollPos.top += this._scrollParents[i].scrollTop;
      currentScrollPos.left += this._scrollParents[i].scrollLeft;
    }
    if (!this._scrollPos) {
      return false;
    }

    return !(
      currentScrollPos.top === this._scrollPos.top &&
      currentScrollPos.left === this._scrollPos.left
    );
  }

  cleanupScrollDetection() {
    this._scrollParents = [];
    this._scrollPos = { top: 0, left: 0 };
  }

  initPressDetection(
    event: SyntheticEvent<HTMLElement, MouseEvent | TouchEvent | KeyboardEvent>,
    callback?: () => void
  ) {
    if (!this.props.onPress) return;

    // SyntheticEvent objects are pooled, so persist the event so it can be referenced asynchronously
    event.persist();

    this._pressTimeout = window.setTimeout(() => {
      this._pressTimeout = undefined;
      this.props.onPress && this.props.onPress(event);
      callback && callback();
    }, this.props.pressDelay);
  }

  cancelPressDetection() {
    let willCancel = typeof this._pressTimeout !== "undefined";
    clearTimeout(this._pressTimeout);

    willCancel && this.props.onPressCancel && this.props.onPressCancel();

    // reset the timeout
    this._pressTimeout = undefined;
  }

  onTouchMove(event: SyntheticEvent<HTMLElement, TouchEvent>) {
    if (this._initialTouch) {
      this.processEvent(event);

      if (this.detectScroll()) {
        return this.endTouch(event);
      } else {
        if (this._touchmoveTriggeredTimes++ === 0) {
          this._touchmoveDetectionTimeout = window.setTimeout(() => {
            if (this._touchmoveTriggeredTimes === 1) {
              this.endTouch(event);
            }
          }, 64);
        }
      }

      this.props.onTouchMove && this.props.onTouchMove(event);
      this._lastTouch = getTouchProps(event.nativeEvent.touches[0]);
      var movement = this.calculateMovement(this._lastTouch);
      if (
        movement.x > this.props.pressMoveThreshold ||
        movement.y > this.props.pressMoveThreshold
      ) {
        this.cancelPressDetection();
      }
      if (
        movement.x > (this.props.moveXThreshold || this.props.moveThreshold) ||
        movement.y > (this.props.moveYThreshold || this.props.moveThreshold)
      ) {
        if (this.state.isActive) {
          if (this.props.allowReactivation) {
            this.setState({
              isActive: false,
            });
          } else {
            return this.endTouch(event);
          }
        } else if (this._activeTimeout) {
          this.clearActiveTimeout();
        }
      } else {
        if (!this.state.isActive && !this._activeTimeout) {
          this.setState({
            isActive: true,
          });
        }
      }
    }

    // PINCH SUPPORT...
    //
    // else if (this._initialPinch && event.touches.length === 2 && this.onPinchMove) {
    // 	this.onPinchMove(event);
    // 	event.preventDefault();
    // }
  }

  onTouchEnd(event: SyntheticEvent<HTMLElement, TouchEvent>) {
    if (this._initialTouch) {
      this.processEvent(event);

      var afterEndTouch;
      var movement = this.calculateMovement(this._lastTouch);

      if (
        movement.x <= (this.props.moveXThreshold || this.props.moveThreshold) &&
        movement.y <= (this.props.moveYThreshold || this.props.moveThreshold) &&
        this.props.onTap
      ) {
        event.preventDefault();

        afterEndTouch = () => {
          var finalParentScrollPos = this._scrollParents.map(
            (node: HTMLElement) => node.scrollTop + node.scrollLeft
          );
          var stoppedMomentumScroll = this._scrollParentPos.some((end, i) => {
            return end !== finalParentScrollPos[i];
          });
          if (!stoppedMomentumScroll) {
            this.props.onTap && this.props.onTap(event);
          }
        };
      }
      this.endTouch(event, afterEndTouch);
    }

    // PINCH SUPPORT
    //
    // else if (this.onPinchEnd && this._initialPinch && (event.touches.length + event.changedTouches.length) === 2) {
    // 	this.onPinchEnd(event);
    // 	event.preventDefault();
    // }
  }
  endTouch(
    event?: SyntheticEvent<HTMLElement, TouchEvent>,
    callback?: () => void
  ) {
    this.cancelTouchmoveDetection();
    this.cancelPressDetection();
    this.clearActiveTimeout();
    if (event && this.props.onTouchEnd) {
      this.props.onTouchEnd(event);
    }
    this._initialTouch = undefined;
    this._lastTouch = undefined;
    if (callback) {
      callback();
    }
    if (this.state.isActive) {
      this.setState({
        isActive: false,
      });
    }
  }

  onMouseDown(event: SyntheticEvent<HTMLElement, MouseEvent>) {
    if (window._blockMouseEvents) {
      window._blockMouseEvents = false;
      return;
    }
    if (this.props.onMouseDown && this.props.onMouseDown(event) === false)
      return;
    this.processEvent(event);
    this.initPressDetection(event, this.endMouseEvent);
    this._mouseDown = true;
    this.setState({
      isActive: true,
    });
  }

  onMouseMove(event: SyntheticEvent<HTMLElement, MouseEvent>) {
    if (window._blockMouseEvents || !this._mouseDown) return;
    this.processEvent(event);
    this.props.onMouseMove && this.props.onMouseMove(event);
  }

  onMouseUp(event: SyntheticEvent<HTMLElement, MouseEvent>) {
    if (window._blockMouseEvents || !this._mouseDown) return;
    this.processEvent(event);
    this.props.onMouseUp && this.props.onMouseUp(event);
    this.props.onTap && this.props.onTap(event);
    this.endMouseEvent();
  }

  onMouseOut(event: SyntheticEvent<HTMLElement, MouseEvent>) {
    if (window._blockMouseEvents || !this._mouseDown) return;
    this.processEvent(event);
    this.props.onMouseOut && this.props.onMouseOut(event);
    this.endMouseEvent();
  }

  endMouseEvent() {
    this.cancelPressDetection();
    this._mouseDown = false;
    this.setState({
      isActive: false,
    });
  }

  onKeyUp(event: SyntheticEvent<HTMLElement, KeyboardEvent>) {
    if (!this._keyDown) return;
    this.processEvent(event);
    this.props.onKeyUp && this.props.onKeyUp(event);
    this.props.onTap && this.props.onTap(event);
    this._keyDown = false;
    this.cancelPressDetection();
    this.setState({
      isActive: false,
    });
  }

  onKeyDown(event: SyntheticEvent<HTMLElement, KeyboardEvent>) {
    if (this.props.onKeyDown && this.props.onKeyDown(event) === false) return;
    if (
      event.nativeEvent.which !== SPACE_KEY &&
      event.nativeEvent.which !== ENTER_KEY
    )
      return;
    if (this._keyDown) return;
    this.initPressDetection(event, this.endKeyEvent);
    this.processEvent(event);
    this._keyDown = true;
    this.setState({
      isActive: true,
    });
  }

  endKeyEvent() {
    this.cancelPressDetection();
    this._keyDown = false;
    this.setState({
      isActive: false,
    });
  }

  cancelTap() {
    this.endTouch();
    this._mouseDown = false;
  }

  handlers() {
    return {
      onTouchStart: this.onTouchStart,
      onTouchMove: this.onTouchMove,
      onTouchEnd: this.onTouchEnd,
      onMouseDown: this.onMouseDown,
      onMouseUp: this.onMouseUp,
      onMouseMove: this.onMouseMove,
      onMouseOut: this.onMouseOut,
      onKeyDown: this.onKeyDown,
      onKeyUp: this.onKeyUp,
    };
  }

  render() {
    var props = this.props;
    var className =
      props.classBase + (this.state.isActive ? "-active" : "-inactive");

    if (props.className) {
      className += " " + props.className;
    }

    if (props.classes) {
      className +=
        " " +
        (this.state.isActive ? props.classes.active : props.classes.inactive);
    }

    let style = { ...touchStyles, ...props.style };

    let newComponentProps = objectWithoutKeys(
      {
        ...props,
        style: style,
        className: className,
        disabled: props.disabled,
        handlers: this.handlers,
        ref: this.elementRef,
        ...this.handlers(),
      },
      [
        "activeDelay",
        "allowReactivation",
        "classBase",
        "classes",
        "handlers",
        "onTap",
        "onPress",
        "onPressCancel",
        "onPinchStart",
        "onPinchMove",
        "onPinchEnd",
        "onDeactivate",
        "onReactivate",
        "moveThreshold",
        "moveXThreshold",
        "moveYThreshold",
        "pressDelay",
        "pressMoveThreshold",
        "preventDefault",
        "stopPropagation",
        "component",
      ]
    );

    return React.createElement(
      props.component,
      newComponentProps,
      props.children
    );
  }
}

export default Tappable;
