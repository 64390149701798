import { AxiosRequestConfig, AxiosResponse } from "axios";
import { call, put, select } from "redux-saga/effects";

import { client } from "../client";
import { updateTokens } from "./actions";
import { log } from "../logs/actions";
import { AuthState } from "../auth/types";
import { TokenPair } from "./types";
import { getRefreshToken, getTokens } from "./selectors";

function* refreshTokens() {
  yield put(log("refreshing tokens"));
  let refreshToken = yield select(getRefreshToken);

  let tokens: TokenPair = yield call(callApi, {
    url: "/device/token/refresh/",
    useToken: false,
    data: { refresh: refreshToken },
  });

  yield put(updateTokens(tokens));
  yield put(log("tokens updated"));

  return tokens;
}

function* getToken() {
  let { accessToken, accessExpiry }: AuthState = yield select(getTokens);

  // if the expiration time is more than 10 minutes away, just give
  // back the access token, otherwise we'll refresh it
  if (
    accessExpiry &&
    new Date(accessExpiry).getTime() - new Date().getTime() > 600000
  ) {
    return accessToken;
  }

  // if not, then we need to fetch the new tokens
  // and then return the accessToken
  let tokens: TokenPair = yield call(refreshTokens);
  return tokens.access;
}

interface CallApiProps extends AxiosRequestConfig {
  url: string;
  method?: "get" | "post";
  data?: any;
  headers?: any;
  useToken?: boolean;
}

function* callApi({
  url,
  method = "post",
  data,
  useToken = true,
  headers = {},
  ...config
}: CallApiProps): any {
  if (useToken) {
    let token = yield call(getToken);
    headers.authorization = `Bearer ${token}`;
  }

  let response: AxiosResponse = yield call(client.request, {
    url,
    method,
    data,
    headers,
    ...config,
  });

  if (response.status < 200 && 300 <= response.status) {
    throw new ApiError(response.status, response.statusText);
  }

  return response.data;
}

class ApiError extends Error {
  statusCode: number;

  constructor(code: number, message: string) {
    super(message);

    this.statusCode = code;
    this.name = "TapynessApiError";
  }
}

export { callApi, refreshTokens };
