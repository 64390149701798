import MockAdapter from "axios-mock-adapter";

const mockConfig = {
  delayResponse: 750,
};

const configV2 = {
  organizationId: 31,
  locationId: 66,
  locationName: "Rick's",
  questionId: 412,
  kioskId: 123,
  kioskUuid: "Xt2JE7NVh8MTiPq54gxDaQ",
  kioskName: "Front Counter",
};

const questionV2 = {
  questionId: 412,
  languages: ["english", "spanish"],
  questions: {
    0: {
      style: {
        color: "#ffffff",
      },
      text: [`How was your experience\nat Eddy's today?`, "Como si bueno?"],
    },
    632: {
      style: {
        color: "#ffffff",
      },
      text: ["What was your favorite part?", "Ytu?"],
    },
  },
  answers: {
    0: [
      {
        id: 590,
        text: ["Poor", "Pore"],
        icon: "",
        value: -1,
        seq: 0,
        hasSpecificAnswers: true,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#FF4649",
          borderColor: "#E90031",
          color: "#0C0001",
        },
      },
      {
        id: 599,
        text: ["Good", "Bueno"],
        icon: "",
        value: 0,
        seq: 1,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#FEE307",
          borderColor: "#DCC402",
          color: "#0C0001",
        },
      },
      {
        id: 632,
        text: ["Great", "Muy Bueno"],
        icon: "",
        value: 1,
        seq: 2,
        hasSpecificAnswers: true,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#00D67F",
          borderColor: "#00AF7E",
          color: "#0C0001",
        },
      },
    ],
    590: [
      {
        id: 690,
        text: ["Location", "Locale"],
        icon: "",
        value: -1,
        seq: 0,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#FF4649",
          borderColor: "#E90031",
          color: "#0C0001",
        },
      },
      {
        id: 799,
        text: ["Food", "Puntas"],
        icon: "",
        value: 0,
        seq: 1,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#FF4649",
          borderColor: "#E90031",
          color: "#0C0001",
        },
      },
      {
        id: 832,
        text: ["Service", "Cervas"],
        icon: "",
        value: 1,
        seq: 2,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#FF4649",
          borderColor: "#E90031",
          color: "#0C0001",
        },
      },
    ],
    632: [
      {
        id: 1590,
        text: ["Hamburger", "regrubmaH"],
        icon: "",
        value: -1,
        seq: 0,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#00D67F",
          borderColor: "#00AF7E",
          color: "#0C0001",
        },
      },
      {
        id: 1599,
        text: ["Fries", "Poonta"],
        icon: "",
        value: 0,
        seq: 1,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#00D67F",
          borderColor: "#00AF7E",
          color: "#0C0001",
        },
      },
      {
        id: 1632,
        text: ["Selection", "Selecion"],
        icon: "",
        value: 1,
        seq: 2,
        hasSpecificAnswers: false,
        confirmationText: ["Thank you!", "👋"],
        style: {
          backgroundColor: "#00D67F",
          borderColor: "#00AF7E",
          color: "#0C0001",
        },
      },
    ],
  },
  theme: {
    backgroundColor: "#999999",
    questionTextColor: "#FFFFFF",
    confirmationText: "Thank you!",
    isPoweredBy: true,
    brandLogo: "",
    tapynessLogoBackgroundColor: "#999999",
    tapynessLogoForegroundColor: "#FFFFFF",
  },
};

function makeToken() {
  const kiosk_id = 123;
  const exp = (Date.now() + 1000 * 60 * 60 * 2) / 1000;

  const prepare = (data) => btoa(JSON.stringify(data));

  let kioskData = {
    kiosk_id,
    exp,
  };
  let claims = {
    exp,
  };

  // return a fake JWT
  return `${prepare(claims)}.${prepare(
    kioskData
  )}.oupouasydfpiuasydfoiuasydfpausydfoasidfypaosidufaopsidufopiasdufoipasdufoipsauopifduopaisfu`;
}

const handleHeartbeat = () => {
  if (Math.random() > 0.7) {
    return [
      200,
      {
        status: "OK",
        recievedAt: new Date().toJSON(),
        messages: [
          {
            message: {
              service: "getDeviceInfo",
              params: {},
            },
            kiosk: "808",
            jobId: "c308f021-efce-4905-ab61-3d33e1ec2017",
            publishedAt: "2019-08-14T04:50:54.219153+00:00Z",
            eventId: "1565758254220-0",
          },
        ],
      },
    ];
  }

  return [200, { status: "OK", recievedAt: new Date().toJSON() }];
};

const obtainTokenPair = () => ({ access: makeToken(), refresh: makeToken() });
const handleTokenRefresh = () => [200, obtainTokenPair()];
const handleTokenAuth = () => [200, obtainTokenPair()];
const handleGetQuestion = () => [200, questionV2];
const handleGetConfig = () => [200, configV2];

export default function withMock(axios) {
  let mock = new MockAdapter(axios, mockConfig);

  mock.onGet("/device/pincode/").reply(200, {
    pin_code: "ABC123",
    secret: "1218412058712059182091284",
  });

  mock
    .onPost("/device/token/") /*.reply(401) */
    .reply(handleTokenAuth);

  mock.onPost("/messages/").reply((args) => {
    console.log("MESSAGES", args);

    return [200];
  });

  mock.onPost("/messages/ack/").reply((args) => {
    console.log("ACK", args);
    return [200];
  });

  mock.onPost("/device/token/refresh/").reply(handleTokenRefresh);
  mock.onPost("/device/heartbeat/").reply(handleHeartbeat);

  mock
    .onGet("/device/config/")
    .replyOnce(404)
    .onGet("/device/config/")
    .reply(handleGetConfig);

  mock
    .onGet("/question/") /*.reply(404) */
    .reply(handleGetQuestion);

  mock.onPost("/vote/").reply((config) => {
    if (Math.random() > 0.5) {
      let error = new Error("Network Error");
      error.config = config;
      return Promise.reject(error);
    }

    return [200];
  });

  return mock;
}
