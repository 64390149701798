import {
  QUESTION_REQUESTED,
  QUESTION_ERROR,
  QUESTION_SUCCESS,
  SHOW_SPECIFIC_ANSWERS,
  VOTE_BUTTON_TAPPED,
  VOTE_SUBMIT,
  VOTE_FAILURE,
  VOTE_SUCCESS,
  CACHED_VOTES_SUBMIT_SUCCESS,
  SEND_STORED_VOTES,
  STORE_VOTES,
  NEXT_LANGUAGE,
  START_LANGUAGE_ROTATION,
  PAUSE_LANGUAGE_ROTATION,
} from "../actionTypes";

let nextLanguage = () => ({
  type: NEXT_LANGUAGE,
  payload: {},
});

let startLanguageRotation = () => ({
  type: START_LANGUAGE_ROTATION,
  payload: {},
});

let pauseLanguageRotation = () => ({
  type: PAUSE_LANGUAGE_ROTATION,
  payload: {},
});

let getQuestion = () => ({
  type: QUESTION_REQUESTED,
  payload: {},
});

let storeVotes = (voteData) => ({
  type: STORE_VOTES,
  payload: {
    votes: voteData,
  },
});

let sendStoredVotes = () => ({
  type: SEND_STORED_VOTES,
  payload: {},
});

let voteFailure = (timestamp, answer_value, specific_value) => ({
  type: VOTE_FAILURE,
  payload: {
    timestamp,
    answer_value,
    specific_value,
  },
});

let voteSubmit = () => ({
  type: VOTE_SUBMIT,
  payload: {},
});

let voteSuccess = () => ({
  type: VOTE_SUCCESS,
  payload: {},
});

let showSecondaryAnswers = (answer_id) => ({
  type: SHOW_SPECIFIC_ANSWERS,
  payload: {
    answer_id,
  },
});

let voteTapped = (answer_id, answer_value) => {
  let timestamp = new Date().toJSON();

  return {
    type: VOTE_BUTTON_TAPPED,
    payload: {
      answer_id,
      answer_value,
      timestamp,
    },
  };
};

let submitVote = (vote_value, specific_value) => ({
  type: VOTE_SUBMIT,
  payload: {
    vote_value,
    specific_value,
  },
});

let setQuestionData = ({
  questionId,
  languages,
  questions,
  answers,
  theme,
}) => ({
  type: QUESTION_SUCCESS,
  payload: {
    questionId,
    languages,
    questions,
    answers,
    theme,
  },
});

let questionError = (error) => ({
  type: QUESTION_ERROR,
  payload: {
    error,
  },
});

let cachedVotesSubmitted = () => ({
  type: CACHED_VOTES_SUBMIT_SUCCESS,
  payload: {},
});

export {
  getQuestion,
  storeVotes,
  sendStoredVotes,
  voteFailure,
  voteSubmit,
  voteSuccess,
  showSecondaryAnswers,
  voteTapped,
  submitVote,
  setQuestionData,
  questionError,
  cachedVotesSubmitted,
  nextLanguage,
  startLanguageRotation,
  pauseLanguageRotation,
};
