import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import Router from "./js/kiosk/Router";
import KioskErrorBoundary from "./js/kiosk/KioskErrorBoundary";
import KioskLoader from "./js/kiosk/components/KioskLoader";
import configureStore from "./js/kiosk/store";
import {
  serviceWorkerReady,
  updateServiceWorkerStatus,
} from "./js/kiosk/actions/kiosk";
import conf from "./js/kiosk/config";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Sentry.init({
  dsn: "https://6e53c28abb58474b91963997158bed87@o163206.ingest.sentry.io/1231789",
  integrations: [new BrowserTracing()],
  environment: conf?.environment ?? "production",
  enabled: conf?.environment !== "local",
  release: conf?.version,
  tracesSampleRate: 0.1,
});

Sentry.setContext("kiosk", {
  ...conf,
});

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      persistor={persistor}
      loading={
        <div id="kiosk">
          <KioskLoader />
        </div>
      }
    >
      <KioskErrorBoundary>
        <Router />
      </KioskErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch(serviceWorkerReady()),
  onUpdate: (reg) => store.dispatch(updateServiceWorkerStatus(reg)),
});
