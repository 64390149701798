import React from "react";

type BrandLogoProps = {
  data: string;
};

const BrandLogo = ({ data }: BrandLogoProps) => (
  <img alt="" src={`data:image/png;base64,${data}`} />
);

export default BrandLogo;
