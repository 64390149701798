import React from "react";

type Props = {
  text?: string | null;
};

function PinCode({ text }: Props) {
  if (typeof text === "undefined" || text == null) {
    return <span className="pin-numbers">Error</span>;
  }

  return (
    <span className="pin-numbers">
      {text.split("").map((char, idx) => (
        <span className="pin-number" key={idx}>
          {char}
        </span>
      ))}
    </span>
  );
}

export default PinCode;
export { PinCode };
