export const getAuthToken = ({ auth }) => auth.accessToken;
export const getPinCode = ({ auth }) => auth.pinCode;

export const isAuthenticated = ({ auth }) =>
  !!auth.accessToken && !!auth.pinCode;

export const getVoteValue = ({ kiosk }) => kiosk.activeAnswer;

export const answerHasSpecifics = ({ question }, answer_id) =>
  answer_id in question.answers;

export const getCachedVotes = ({ question }) => question.cachedVotes;
export const getCachedVotesCount = ({ question }) => question.cachedVotesCount;

export const getCacheStatus = ({ kiosk }) => kiosk.cacheStatus;

export const getCurrentQuestion = ({ question }) => question;
export const hasQuestion = ({ question }) => !!question.questionId;
