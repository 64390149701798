import React from "react";

import Tappable from "./Tappable";
import PoweredByLogo from "./PoweredByTapynessLogo";

type Props = {
  onHold?: () => void;
  color?: string;
  backgroundColor?: string;
};

let PoweredByButton: React.FC<Props> = ({ onHold, color, backgroundColor }) => (
  <Tappable
    pressDelay={10000}
    pressMoveThreshold={20}
    onPress={onHold}
    className="svg-container"
    component="div"
  >
    <PoweredByLogo color={color} backgroundColor={backgroundColor} />
  </Tappable>
);

export default PoweredByButton;
