import React from "react";

import cx from "classnames";
import Tappable from "./Tappable";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Legend from "../components/Legend";

import { voteTapped } from "../question/actions";

// type Action = {
//     type: string
//     payload: object
// }

type Question = {
  currentLangIdx?: number;
};

type Props = Question & {
  id: number;
  buttonClass: string;
  confirmationText: string[];
  voteTapped?: () => void;
  icon?: string | null;
  pressClass?: string;
  releaseClass?: string;
  text: string[];
  value: number;
  style: React.CSSProperties;
};

type State = {
  isTouching: boolean;
  isRebounding: boolean;
};

class Button extends React.Component<Props, State> {
  static defaultProps = {
    buttonClass: "vote-button",
    pressClass: "compress",
    releaseClass: "rebound",
  };

  private timeout: ReturnType<typeof setTimeout> | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isTouching: false,
      isRebounding: false,
    };

    this.timeout = null;
  }

  componentWillUnmount() {
    this.timeout = null;
  }

  styles = () => {
    let styleOverride = this.props.style || {};

    return {
      borderWidth: "3px",
      borderStyle: "solid",
      ...styleOverride,
    };
  };

  _touchStart = () => {
    /* this is to cancel any touches that don't complete */
    this.timeout = setTimeout(() => {
      this._touchEnd();
    }, 3000);

    this.setState({ isTouching: true, isRebounding: false });
  };

  _touchEnd = () => {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = null;
    this.setState({ isTouching: false, isRebounding: true });
  };

  _handleTap = () => {
    let { voteTapped } = this.props;
    voteTapped && voteTapped();
  };

  render() {
    let { currentLangIdx, id, text, icon, confirmationText } = this.props;
    let { isTouching, isRebounding } = this.state;

    return (
      <Tappable
        component={"div"}
        key={id}
        classBase="vote-button"
        className={cx({
          "vote-button": true,
          compress: isTouching,
          rebound: !isTouching && isRebounding,
        })}
        style={this.styles()}
        onTouchStart={this._touchStart}
        onTouchEnd={this._touchEnd}
        onMouseDown={this._touchStart}
        onMouseUp={this._touchEnd}
        onTap={this._handleTap}
      >
        {text.map((t, idx) => (
          <Legend
            key={`${t}:${idx}`}
            isActive={idx === currentLangIdx}
            text={t}
            icon={icon}
            confirmationText={confirmationText[idx]}
          />
        ))}
      </Tappable>
    );
  }
}

const mapState = ({ question }: { question: Question }) => ({
  currentLangIdx: question.currentLangIdx,
});

const mapDispatch = (dispatch: Dispatch, ownProps: Props) => ({
  voteTapped: () => dispatch(voteTapped(ownProps.id, ownProps.value)),
});

export default connect(mapState, mapDispatch)(Button);
