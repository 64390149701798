import { DeviceConfigState } from "../device/types";
import { QuestionState } from "./types";

interface StoreType {
  question: QuestionState;
  device: DeviceConfigState;
}

export const answerHasSpecifics = (
  { question }: StoreType,
  answer_id: string
) => answer_id in question.answers;

export const getCachedVotes = ({ question }: StoreType) => question.cachedVotes;
export const getCachedVotesCount = ({ question }: StoreType) =>
  question.cachedVotes.length;

export const getCurrentQuestion = ({ question }: StoreType) => question;
export const hasQuestion = ({ question }: StoreType) => !!question.questionId;
export const getCurrentLanguage = ({ question }: StoreType) =>
  question.languages[question.currentLangIdx];

export const getLanguageRotationIntervalMs = ({ device }: StoreType) =>
  device.languageRotationIntervalMs;
