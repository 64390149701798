import produce from "immer";

import { RESET_KIOSK } from "../actionTypes";

import {
  FETCH_PIN,
  PIN_SUCCESS,
  PIN_ERROR,
  TOKENS_SUCCESS,
  AuthState,
  AuthActionTypes,
  ResetKioskAction,
} from "./types";

const initialState: AuthState = {
  pinCode: null,
  secret: null,
  accessToken: null,
  refreshToken: null,
  accessExpiry: null,
};

const reducer = (
  state = initialState,
  action: AuthActionTypes | ResetKioskAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_KIOSK:
        return initialState;

      case TOKENS_SUCCESS: {
        let { access, refresh, expiry } = action.payload;
        draft.accessExpiry = expiry;
        draft.accessToken = access;
        draft.refreshToken = refresh;
        break;
      }
      case FETCH_PIN:
        draft.pinCode = "";
        draft.secret = "";
        break;
      case PIN_SUCCESS:
        draft.pinCode = action.payload.code;
        draft.secret = action.payload.secret;
        break;
      case PIN_ERROR:
        draft.pinCode = action.payload.code;
        draft.secret = "";
        break;
    }
  });

export default reducer;
