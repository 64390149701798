import React from "react";

import cx from "classnames";

import AnswerButton from "./AnswerButton";

// type ButtonColors = {
//     text_color: string
//     background_color: string
//     border_color: string
// }

type Answer = {
  id: number;
  value: number;
  text: string[];
  className: string;
  icon?: string;
  confirmationText: string[];
  style: React.CSSProperties;
};

type Props = {
  answers: Answer[];
  specificToAnswer: string;
  className?: string;
  style?: React.CSSProperties;
};

const ButtonGroup: React.FC<Props> = ({
  answers,
  // specificToAnswer,
  className,
  style,
}) => {
  let buttonNodes = answers.map(
    ({ id, value, text, className, icon, confirmationText, style }) => (
      <AnswerButton
        key={id}
        id={id}
        buttonClass={className}
        confirmationText={confirmationText}
        icon={icon}
        style={style}
        text={text}
        value={value}
      />
    )
  );

  return (
    <div className={cx("buttons", className)} style={style}>
      {buttonNodes}
    </div>
  );
};

export default ButtonGroup;
