let env = window.ENV || {};

const HTTP_PORT = "80";
const HTTPS_PORT = "443";

// IIFE used to determine what our current URL is
// this is used as a default setting that we'll fallback to
// if the env (window.ENV) isn't provided
let environmentURL = (() => {
  if (window.location && window.location.hostname) {
    // if we're not running on a standard port, then we need to append the port
    if (![HTTP_PORT, HTTPS_PORT].includes(window.location.port)) {
      return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    }

    return `${window.location.protocol}//${window.location.hostname}`;
  }

  // not provided any location information, fallback to localhost
  return "http://127.0.0.1";
})();

let defaults = {
  baseUrl: environmentURL,
  apiUrl: environmentURL,
  apiEndpoint: "/api/v2/kiosks",
  environment: "local",
};

const mergedEnv = { ...defaults, ...env };

// merges our defaults onto the env so we have a fully formed config
export default mergedEnv;
