import { RootState } from "../store/configureStore";

const getHeartbeatInterval = ({ device }: RootState) =>
  device.heartbeatInterval;

const getLastHeartbeat = ({ heartbeats }: RootState) =>
  heartbeats.lastHeartbeat;

const secondsSinceLastHeartbeat = ({ heartbeats }: RootState) =>
  (Date.now() - new Date(heartbeats.lastHeartbeat ?? Date.now()).getTime()) /
  1000;

export { getHeartbeatInterval, getLastHeartbeat, secondsSinceLastHeartbeat };
