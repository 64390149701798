import * as React from "react";

import cx from "classnames";

interface QuestionTextProps {
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const defaultStyle = {
  color: "#000000",
};

let QuestionText: React.FC<QuestionTextProps> = ({
  className,
  text,
  style,
}) => (
  <div
    className={cx("question", className)}
    style={{
      ...defaultStyle,
      ...style,
    }}
  >
    <h1>{text}</h1>
  </div>
);

QuestionText.defaultProps = {
  style: {},
};

export default QuestionText;
