import { MESSAGES_RECEIVED, MessageWrapper, MessageActionTypes } from "./types";

function messagesReceived(messages: MessageWrapper[]): MessageActionTypes {
  return {
    type: MESSAGES_RECEIVED,
    payload: { messages },
  };
}

export { messagesReceived };
