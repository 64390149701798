import React from "react";
import cx from "classnames";

import Checkmark from "./Checkmark";

type Props = {
  icon?: string | null;
  text: string;
  confirmationText?: string | null;
  isActive: boolean;
};

const Legend: React.FC<Props> = ({
  icon,
  text,
  confirmationText,
  isActive,
}) => {
  let legendItems = [];

  if (icon) {
    let iconSrc = "data:image/png;base64," + icon;
    legendItems.push(<img alt="" key={icon} src={iconSrc} />);
  }

  if (text) {
    legendItems.push(<p key={text}>{text}</p>);
  }

  let confirmationNode =
    typeof confirmationText !== "undefined" && confirmationText !== "" ? (
      <div className="confirmation text">{confirmationText}</div>
    ) : (
      <Checkmark />
    );

  return (
    <div className={cx("content", { "content-active": isActive })}>
      <div className="legend">{legendItems}</div>
      {confirmationNode}
    </div>
  );
};

export default Legend;
