import { CancelToken } from "../client";

import { buffers } from "redux-saga";
import {
  actionChannel,
  call,
  delay,
  put,
  race,
  select,
  take,
  flush,
  SelectEffect,
} from "redux-saga/effects";

import { MESSAGES_RECEIVED } from "../actionTypes";
import { callApi } from "../auth";

import config from "../config";

import { updateDeviceConfig } from "../device/actions";
import { getQuestion } from "../actions/question";
import { authSuccess, closeMenu } from "../actions/kiosk";

import { log } from "../logs/actions";
import { getLogEntries } from "../logs/selectors";
import {
  getPinCode,
  getCurrentQuestion,
  getCachedVotesCount,
} from "../selectors";

import getNavigatorInfo from "../navigator";

import {
  MessageWrapper,
  GetLogsMessage,
  InjectReduxActionMessage,
  UpdateDeviceConfigMessage,
} from "./types";

type ServiceHandlerMap = {
  [index: string]: MessageHandler;
};

let serviceMap: ServiceHandlerMap = {
  getLogs,
  changeQuestion,
  getDeviceInfo,
  injectReduxAction,
  updateConfiguration,
  openAdminMenu,
  closeAdminMenu,
};

type MessageHandler = (
  message: MessageWrapper
) => IterableIterator<SelectEffect> | any;

function* updateConfiguration(message: MessageWrapper) {
  let { params: config } = message.message as UpdateDeviceConfigMessage;
  yield put(updateDeviceConfig(config));
  yield put(log("Device config applied"));
}

function* changeQuestion() {
  yield put(getQuestion());
}

function* closeAdminMenu() {
  yield put(closeMenu());
}

function* openAdminMenu() {
  yield put(authSuccess());
}

function* getDeviceInfo(message: MessageWrapper) {
  let device = getNavigatorInfo().getResult();

  let logs = yield select(getLogEntries);
  let lastIndex = logs.length - 1;
  let recentLogs = logs.slice(lastIndex - 5, lastIndex);

  let pinCode = yield select(getPinCode);
  let currentQuestion = yield select(getCurrentQuestion);
  let cachedVotesCount = yield select(getCachedVotesCount);

  return {
    kioskVersion: config.version,
    device,
    recentLogs,
    pinCode,
    currentQuestion,
    cachedVotesCount,
  };
}

/**
 * getLogs
 * this gets the requested number of log entries and reports them
 * back to the server.
 *
 */
function* getLogs(message: MessageWrapper) {
  let logs = yield select(getLogEntries);
  let params = message.message as GetLogsMessage;

  let lastIndex = logs.length - 1;
  let numberOfLines = (params && params.params && params.params.count) || 20;

  return {
    logs: logs.slice(lastIndex - numberOfLines, lastIndex),
  };
}

function* injectReduxAction(message: MessageWrapper) {
  yield put(message.message as InjectReduxActionMessage);

  return {
    message: "Action injected",
    action: message.message,
  };
}

function* messageHandler(message: MessageWrapper) {
  try {
    let { service } = message.message;
    let worker = serviceMap[service];

    if (!worker) {
      yield put(log("invalid service", message));
      return;
    }

    yield put(log(`working new message for ${service}`, message));
    let result: any = yield call(worker, message);

    let { timeout } = yield call(api, {
      event_id: message.eventId,
      job_id: message.jobId,
      data: result,
    });

    if (timeout) throw new Error("Timed out");
  } catch (err) {
    yield put(log("error working on message", err.toString()));

    // rethrow error so we can clear the message queue
    throw err;
  }
}

function* watchMessages() {
  let messageChannel = yield actionChannel(
    MESSAGES_RECEIVED,
    buffers.sliding(100)
  );

  yield put(log("watching for messages..."));

  while (true) {
    let { payload } = yield take(messageChannel);

    if (payload.messages) {
      try {
        for (let message of payload.messages) {
          yield call(messageHandler, message);
        }
      } catch (err) {
        let flushedMessages = yield flush(messageChannel);

        yield put(
          log(
            `Flushed ${flushedMessages.length} messages from queue`,
            flushedMessages
          )
        );

        try {
          yield call(
            api,
            {
              messages: flushedMessages.map(
                (msg: MessageWrapper) => msg.eventId
              ),
              error: err,
            },
            10000,
            "/messages/ack/"
          );
        } catch (err) {
          yield put(log("Error when processing message", err.toString()));
        }
      }
    }
  }
}

function* api(data: any, timeoutMs = 10000, url = "/messages/") {
  let source = CancelToken.source();

  let { timeout, response } = yield race({
    timeout: delay(timeoutMs), // after 10 seconds, we're going to pull the cord on the request
    response: call(callApi, {
      url: "/messages/",
      data,
      cancelToken: source.token,
    }),
  });

  if (timeout) {
    source.cancel();
    return { timeout: true };
  }

  return { response };
}

export { watchMessages };
