import {
  QuestionConfig,
  QuestionActionTypes,
  VotePayload,
  QUESTION_REQUESTED,
  QUESTION_ERROR,
  QUESTION_SUCCESS,
  SHOW_SPECIFIC_ANSWERS,
  VOTE_BUTTON_TAPPED,
  VOTE_SUBMIT,
  VOTE_FAILURE,
  VOTE_SUCCESS,
  CACHED_VOTES_SUBMIT_SUCCESS,
  SEND_STORED_VOTES,
  STORE_VOTES,
  NEXT_LANGUAGE,
  START_LANGUAGE_ROTATION,
  PAUSE_LANGUAGE_ROTATION,
} from "./types";

let nextLanguage = (): QuestionActionTypes => ({
  type: NEXT_LANGUAGE,
});

let startLanguageRotation = (): QuestionActionTypes => ({
  type: START_LANGUAGE_ROTATION,
});

let pauseLanguageRotation = (): QuestionActionTypes => ({
  type: PAUSE_LANGUAGE_ROTATION,
});

let getQuestion = (): QuestionActionTypes => ({
  type: QUESTION_REQUESTED,
});

let storeVotes = (votes: VotePayload[]): QuestionActionTypes => ({
  type: STORE_VOTES,
  payload: {
    votes,
  },
});

let sendStoredVotes = (): QuestionActionTypes => ({
  type: SEND_STORED_VOTES,
});

let voteFailure = (voteData: VotePayload): QuestionActionTypes => ({
  type: VOTE_FAILURE,
  payload: voteData,
});

let voteSubmit = (): QuestionActionTypes => ({
  type: VOTE_SUBMIT,
});

let voteSuccess = (): QuestionActionTypes => ({
  type: VOTE_SUCCESS,
});

let showSecondaryAnswers = (answer_id: string): QuestionActionTypes => ({
  type: SHOW_SPECIFIC_ANSWERS,
  payload: {
    answer_id,
  },
});

let voteTapped = (
  answer_id: number,
  answer_value: number
): QuestionActionTypes => {
  let now = new Date();
  let timestamp = now.getTime();

  return {
    type: VOTE_BUTTON_TAPPED,
    payload: {
      answer_id: answer_id.toString(),
      answer_value,
      time_string: now.toJSON(),
      timestamp,
    },
  };
};

let setQuestionData = ({
  questionId,
  languages,
  questions,
  answers,
  theme,
  locationLogo,
}: QuestionConfig): QuestionActionTypes => ({
  type: QUESTION_SUCCESS,
  payload: {
    questionId,
    languages,
    questions,
    answers,
    theme,
    locationLogo,
  },
});

let questionError = (error: Error): QuestionActionTypes => ({
  type: QUESTION_ERROR,
  payload: {
    error,
  },
});

let cachedVotesSubmitted = (): QuestionActionTypes => ({
  type: CACHED_VOTES_SUBMIT_SUCCESS,
});

export {
  getQuestion,
  storeVotes,
  sendStoredVotes,
  voteFailure,
  voteSubmit,
  voteSuccess,
  showSecondaryAnswers,
  voteTapped,
  setQuestionData,
  questionError,
  cachedVotesSubmitted,
  nextLanguage,
  startLanguageRotation,
  pauseLanguageRotation,
};
