import React from "react";
import { connect } from "react-redux";

import ButtonGroup from "./components/ButtonGroup";
import QuestionText from "./components/QuestionText";

import "../../css/kiosk.scss";

type Answer = {
  id: number;
  value: number;
  text: string[];
  className: string;
  icon?: string;
  confirmationText: string[];
  style: React.CSSProperties;
};

type Question = {
  activeAnswer: string;
  answers: {
    string: Answer[];
  };
  questions: {
    string: {
      text: string[];
      answers?: string[];
      style?: React.CSSProperties;
    };
  };
  currentLangIdx: number;
};

type Props = Question;

const Kiosk: React.FC<Props> = ({
  questions,
  answers,
  activeAnswer,
  currentLangIdx,
}) => (
  <>
    <div className="question-container">
      {Object.entries(questions).map(([key, q]) =>
        q.text.map((text, idx) => (
          <QuestionText
            key={`${idx}-${key}`}
            className={
              ((key === "0" && !(activeAnswer in questions)) ||
                key === activeAnswer) &&
              idx === currentLangIdx
                ? "question-active"
                : undefined
            }
            text={text}
            style={q.style}
          />
        ))
      )}
    </div>

    {Object.entries(answers).map(([key, answers]) => (
      <ButtonGroup
        key={key}
        className={key === activeAnswer ? "buttons-active" : undefined}
        specificToAnswer={key}
        answers={answers}
      />
    ))}
  </>
);

function mapStateToProps({ question }: { question: Question }) {
  return {
    questions: question.questions,
    answers: question.answers,
    activeAnswer: question.activeAnswer.toString(),
    currentLangIdx: question.currentLangIdx,
  };
}

export default connect(mapStateToProps)(Kiosk);
export { Kiosk };
