import React from "react";
import SquareTapynessLogo from "./SquareTapynessLogo";

interface Props {
  size?: string;
  text?: string;
  color?: string;
}

const TapynessCyan = "#07B9FE";

let LogoFlag: React.FC<Props> = ({
  text,
  size = "100px",
  color = TapynessCyan,
  children,
}) => (
  <div className="logo-flag">
    <SquareTapynessLogo size={size} />
    {text && <span className="text">{text}</span>}
    {children}
  </div>
);

export default LogoFlag;
