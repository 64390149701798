import {
  HEARTBEAT_START,
  HEARTBEAT_SUCCESS,
  HeartbeatActionType,
} from "./types";

let startHeartbeat = (): HeartbeatActionType => ({
  type: HEARTBEAT_START,
});

let heartbeatSuccess = (timestamp: string): HeartbeatActionType => ({
  type: HEARTBEAT_SUCCESS,
  payload: {
    timestamp,
  },
});

export { startHeartbeat, heartbeatSuccess };
