import { AuthState } from "./types";

interface StoreType {
  auth: AuthState;
}

const getTokens = (store: StoreType) => store.auth;
const getAccessToken = (store: StoreType) => store.auth.accessToken;
const getRefreshToken = (store: StoreType) => store.auth.refreshToken;
const getExiryTime = (store: StoreType) => store.auth.accessExpiry;

export { getTokens, getAccessToken, getRefreshToken, getExiryTime };
