import { RESET_KIOSK } from "../actionTypes";

export const FETCH_PIN = "pin/fetching";
export const PIN_REFRESHING = "pin/refreshing";
export const PIN_SUCCESS = "pin/success";
export const PIN_ERROR = "pin/fail";

export const TOKEN_REFRESHING = "tokens/refreshing";
export const TOKENS_SUCCESS = "tokens/success";
export const TOKEN_ERROR = "tokens/fail";

export interface AuthState {
  pinCode: string | null;
  secret: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  accessExpiry: Date | null;
}

export interface TokenPair {
  access: string;
  refresh: string;
}

interface SetPinAction {
  type: typeof PIN_SUCCESS;
  payload: {
    code: string;
    secret: string;
  };
}

interface FetchPinAction {
  type: typeof FETCH_PIN;
}

interface UpdateTokensAction {
  type: typeof TOKENS_SUCCESS;
  payload: {
    access: string;
    refresh: string;
    expiry: Date;
  };
}

interface PinErrorAction {
  type: typeof PIN_ERROR;
  payload: {
    error: typeof Error;
    code: "ERROR";
  };
}

export interface ResetKioskAction {
  type: typeof RESET_KIOSK;
}

export type AuthActionTypes =
  | SetPinAction
  | FetchPinAction
  | UpdateTokensAction
  | PinErrorAction;
