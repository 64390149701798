import axios, { AxiosRequestConfig } from "axios";
import conf from "./config";

if (process.env.REACT_APP_USE_MOCK_API) {
  console.log("USING MOCK API");
  const withMock = require("./mocks").default;
  withMock(axios);
}

/**
 * create axios client with common base Url for this env
 * if provided a token, then appropriate headers are configured
 *
 * @param {string} token JWT token to authorize this request
 * @param {object} options configuration for axios client
 *
 * @returns {object} axios client
 *
 */
function createClient(token?: string, options?: Partial<AxiosRequestConfig>) {
  let baseURL = `${conf.apiUrl}${conf.apiEndpoint}`;
  let headers: any = {
    accept: "application/json",
    "content-type": "application/json",
    "x-tapyness-type": "kiosk",
    "x-tapyness-kiosk-version": conf.version,
  };

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return axios.create({
    baseURL,
    headers,
    ...options,
  });
}

const client = createClient();
const CancelToken = axios.CancelToken;

export default client;
export { client, createClient, CancelToken };
