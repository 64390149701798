import React, { useState } from "react";

import cx from "classnames";

function useToggle(initialValue = false) {
  const [on, setOn] = useState(initialValue);
  const toggle = () => setOn(!on);

  return [on, toggle];
}

type Props = {
  title?: string;
  children: React.ReactChild;
};

const DebugBlock: React.FC<Props> = ({ title, children }) => {
  let [fullscreen, toggleFullscreen] = useToggle(false);

  return (
    <div
      className={cx("debug-block", {
        open: fullscreen,
      })}
      onClick={toggleFullscreen as React.MouseEventHandler}
    >
      {title && <div className="debug-block-title">{title}</div>}
      <pre className="debug-block-content">{children}</pre>
    </div>
  );
};

export default DebugBlock;
