import produce from "immer";

import {
  FETCH_PIN,
  PIN_SUCCESS,
  PIN_ERROR,
  RESET_KIOSK,
  OPEN_MENU,
  CLOSE_MENU,
  MENU_AUTH_SUCCESS,
  MENU_AUTH_FAILURE,
  QUESTION_SUCCESS,
  UPDATE_STATUS,
  SERVICE_WORKER_READY,
  UPDATE_SERVICE_WORKER_STATUS,
} from "../actionTypes";

type KioskActionType =
  | typeof FETCH_PIN
  | typeof PIN_SUCCESS
  | typeof PIN_ERROR
  | typeof RESET_KIOSK
  | typeof OPEN_MENU
  | typeof CLOSE_MENU
  | typeof MENU_AUTH_SUCCESS
  | typeof MENU_AUTH_FAILURE
  | typeof QUESTION_SUCCESS
  | typeof UPDATE_STATUS
  | typeof SERVICE_WORKER_READY
  | typeof UPDATE_SERVICE_WORKER_STATUS;

type KioskAction = {
  type: KioskActionType;
  payload: any;
};

type KioskState = {
  isLoading: boolean;
  showingMenu: boolean;
  menuAuthenticated: boolean;
  cacheStatus: number;
  kiosk_id: string;
  heartbeatInterval: number;

  serviceWorkerReady: boolean;
  serviceWorkerRegistration: ServiceWorkerRegistration | null;
};

let initialKioskState: KioskState = {
  isLoading: true,
  showingMenu: false,
  menuAuthenticated: false,
  cacheStatus: 0,
  kiosk_id: "",
  heartbeatInterval: 60000,

  serviceWorkerReady: false,
  serviceWorkerRegistration: null,
};

const kiosk = produce((draft: KioskState, action: KioskAction) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_KIOSK:
      return initialKioskState;

    case MENU_AUTH_FAILURE:
    case CLOSE_MENU:
      draft.showingMenu = false;
      draft.menuAuthenticated = false;
      break;

    case OPEN_MENU:
      draft.showingMenu = true;
      break;

    case QUESTION_SUCCESS:
      draft.kiosk_id = payload.kioskId;
      break;

    case MENU_AUTH_SUCCESS:
      draft.showingMenu = true;
      draft.menuAuthenticated = true;
      break;

    case PIN_SUCCESS:
      draft.isLoading = false;
      break;

    case PIN_ERROR:
      draft.isLoading = false;
      break;

    case FETCH_PIN:
      draft.isLoading = true;
      break;

    case UPDATE_STATUS:
      draft.cacheStatus = payload.status;
      break;

    case SERVICE_WORKER_READY:
      draft.serviceWorkerReady = true;
      break;

    case UPDATE_SERVICE_WORKER_STATUS: {
      if ("registration" in payload && payload.registration) {
        draft.serviceWorkerReady = true;
        draft.serviceWorkerRegistration =
          payload.registration as ServiceWorkerRegistration;
      }
      break;
    }
    default:
      break;
  }
}, initialKioskState);

export default kiosk;
