import produce from "immer";

import { RESET_KIOSK } from "../actionTypes";
import { ResetKioskAction } from "../auth/types";

import {
  QuestionActionTypes,
  QUESTION_SUCCESS,
  SHOW_SPECIFIC_ANSWERS,
  VOTE_SUBMIT,
  VOTE_FAILURE,
  CACHED_VOTES_SUBMIT_SUCCESS,
  STORE_VOTES,
  START_LANGUAGE_ROTATION,
  NEXT_LANGUAGE,
  QuestionState,
} from "./types";

let initialQuestionState: QuestionState = {
  questionId: null,
  locationLogo: null,
  languages: [],
  questions: {},
  answers: {},
  theme: {},
  activeAnswer: "0",
  currentLangIdx: 0,
  cachedVotes: [],
};

const question = (
  state = initialQuestionState,
  action: QuestionActionTypes | ResetKioskAction
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_KIOSK:
        return initialQuestionState;

      case START_LANGUAGE_ROTATION:
        draft.currentLangIdx = 0;
        break;

      case NEXT_LANGUAGE:
        draft.currentLangIdx =
          (+draft.currentLangIdx + 1) % draft.languages.length;
        break;

      case SHOW_SPECIFIC_ANSWERS:
        draft.activeAnswer = action.payload.answer_id;
        break;

      case VOTE_SUBMIT:
        draft.activeAnswer = "0";
        break;

      case VOTE_FAILURE:
        draft.cachedVotes.push(action.payload);
        break;

      case STORE_VOTES:
        draft.cachedVotes.concat(action.payload.votes);
        break;

      case QUESTION_SUCCESS:
        draft.questionId = action.payload.questionId;
        draft.locationLogo = action.payload.locationLogo;
        draft.languages = action.payload.languages;
        draft.questions = action.payload.questions;
        draft.answers = action.payload.answers;
        draft.theme = action.payload.theme;
        break;

      case CACHED_VOTES_SUBMIT_SUCCESS:
        draft.cachedVotes = [];
        break;
    }
  });

export default question;
