import React from "react";

import LogoFlag from "./components/LogoFlag";
import { captureException } from "@sentry/react";

interface Props {
  children: React.ReactChild;
}
interface State {
  error: Error | null;
}

class KioskErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureException(error);
    console.error("ERROR", error, errorInfo);
  }

  render() {
    let { error } = this.state;
    let { children } = this.props;

    if (error) {
      return <LogoFlag text="Oh No!" />;
    }
    return children;
  }
}

export default KioskErrorBoundary;
