import {
  FETCH_PIN,
  PIN_ERROR,
  PIN_SUCCESS,
  TOKENS_SUCCESS,
  AuthActionTypes,
  TokenPair,
} from "./types";

let setPIN = (code: string, secret: string): AuthActionTypes => ({
  type: PIN_SUCCESS,
  payload: {
    code,
    secret,
  },
});

let fetchPIN = (): AuthActionTypes => ({
  type: FETCH_PIN,
});

let updateTokens = ({ access, refresh }: TokenPair): AuthActionTypes => {
  // get the payload from our JWT, decode it (b64)
  // and then parse it. The exp is in seconds, we
  // need ms, so we'll multiply by 1000
  let data = access.split(".")[1];
  let { exp } = JSON.parse(atob(data));

  let expiry = new Date(exp * 1000);

  return {
    type: TOKENS_SUCCESS,
    payload: {
      access,
      refresh,
      expiry,
    },
  };
};

let pinError = (e: typeof Error): AuthActionTypes => ({
  type: PIN_ERROR,
  payload: {
    error: e,
    code: "ERROR",
  },
});

export { setPIN, fetchPIN, updateTokens, pinError };
