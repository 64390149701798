import {
  call,
  delay,
  put,
  fork,
  take,
  race,
  takeLatest,
} from "redux-saga/effects";
import { REHYDRATE } from "redux-persist/lib/constants";

import { callApi } from "../auth";
import { RESET_KIOSK } from "../actionTypes";

import { setPIN, pinError, updateTokens } from "../auth/actions";

import { questionSaga, languageSaga } from "../question/sagas";
import { heartbeatSaga } from "../heartbeats/sagas";
import { watchMessages } from "../messages/sagas";

import { menuSaga } from "./menu";
import deviceFlowSaga from "./device";

import { log } from "../logs/actions";

import {
  FETCH_PIN,
  TOKENS_SUCCESS,
  START_LANGUAGE_ROTATION,
} from "../actionTypes";

const ACCESS_TOKEN = "access";
const REFRESH_TOKEN = "refresh";
const AUTH_LOOP_INTERVAL = 5000;

/*
 *          SAGAS START HERE
 *  We'll yield our sagas here to start the app
 *
 */
export default function* rootSaga() {
  // eslint-disable-next-line no-console
  console.log("starting sagas...");

  try {
    yield take(REHYDRATE);
    yield put(log("kiosk rehydrated"));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("ERROR WHILE REHYDRATING THE KIOSK");
  }

  try {
    yield fork(questionSaga);
    yield fork(heartbeatSaga);
    yield fork(menuSaga);
    yield fork(watchMessages);

    yield takeLatest([FETCH_PIN], fetchPIN);
    yield takeLatest([RESET_KIOSK], deviceFlowSaga);
    yield takeLatest([START_LANGUAGE_ROTATION], languageSaga);

    yield put(log("sagas started."));

    yield put(log("start device boot up"));
    yield* deviceFlowSaga();
    yield put(log("device ready"));
  } catch (e) {
    /* eslint-disable no-console */
    console.error("SAGAS STOPPED");
    console.error(e.toString());
    console.error(e);
    /* eslint-enable no-console */
  }
}

/*
 * This saga is our auth loop, checking the server
 * for a JWT until either:
 * - the user requests a new PIN -> kill this loop and start a new loop
 * - the server returns a JWT
 *
 */
export function* checkForAuthSaga(pin_code, secret) {
  // while the saga is running, keep looping
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // in this saga, step 1 is to count to authLoopInterval ms
      yield delay(AUTH_LOOP_INTERVAL);

      // step 2, once the delay is over, we call the auth endpoint with PIN&secret
      let results = yield call(callApi, {
        url: "/device/token/",
        useToken: false,
        data: { pin_code, secret },
      });

      // if we get our JWTs, we're done!
      if (ACCESS_TOKEN in results && REFRESH_TOKEN in results) {
        // valid tokens, save them
        return yield put(updateTokens(results));
      }
    } catch (err) {
      yield put(log("Unable to activate Kiosk", err));
    }
  }
}

/*
 *  This saga is responsible for fetching the PIN from the server
 *  that the user will use to add the kiosk to their account. The PIN
 *  comes with a secret, this pair will be used to get a JWT.
 *
 */
export function* fetchPIN() {
  try {
    // step 1: call our API to get a PIN/secret
    let { pin_code, secret } = yield call(callApi, {
      url: "/device/pincode/",
      method: "get",
      useToken: false,
    });

    // step 2: if successul, put the pin/secret in state
    // for display to the user
    yield put(setPIN(pin_code, secret));

    // step 3: off to the races!
    // We're going to run our authLoop with the new pin/secret combo
    // loop will run forever, but our event will kill the loop when
    // either: the user requests a new PIN OR if our Token is updated

    return yield race({
      authLoop: call(checkForAuthSaga, pin_code, secret),
      cancel: take([FETCH_PIN, TOKENS_SUCCESS]),
    });
  } catch (err) {
    // this is step 2b: if our api call fails, we'll update the UI
    // with that information.

    yield put(log("Unable to fetch pin/secret", { err }));
    yield put(pinError(err));
  }
}
