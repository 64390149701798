import { createStore, applyMiddleware } from "redux";

import { persistStore, persistCombineReducers } from "redux-persist";

import { composeWithDevTools } from "redux-devtools-extension";

import localForage from "localforage";

import createSagaMiddleware from "redux-saga";

import rootSaga from "../sagas";

import { kiosk } from "../reducers";
import { reducer as auth } from "../auth";
import { reducer as device } from "../device";
import { reducer as logs } from "../logs";
import { reducer as heartbeats } from "../heartbeats";
import { reducer as question } from "../question";

let config = {
  key: "primary",
  storage: localForage,
};

let sagaMiddleware = createSagaMiddleware();
let composeEnhancers = composeWithDevTools({});

let persistedRootReducer = persistCombineReducers(config, {
  auth,
  device,
  heartbeats,
  // @ts-ignore
  kiosk,
  // @ts-ignore
  logs,
  question,
});

let store = createStore(
  persistedRootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

export default function configureStore() {
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  return {
    store,
    persistor,
  };
}

export type RootReducer = typeof persistedRootReducer;
export type RootState = ReturnType<RootReducer>;
