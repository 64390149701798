import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

import rootSaga from "../sagas";
import { kiosk } from "../reducers";
import { reducer as question } from "../question";
import heartbeats from "../heartbeats/reducer";

const sagaMiddleware = createSagaMiddleware();

// used for our tests, where we don't have storage to deal with
const configureTestStore = (initialState = Map()) => {
  const store = createStore(
    combineReducers({ kiosk, heartbeats, question }),
    initialState,
    applyMiddleware(sagaMiddleware)
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureTestStore;
export { configureTestStore };
